import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getMatchById } from '../../services/MatchService';
import MatchDetail from './MatchDetail';
import Scoreboard from './Scoreboard';
import { Match } from '../../models/Match';
import TeamLineup from '../../components/TeamLineup';
import Loading from '../../components/Loading';
import { getPlayerMatchBySteamIdAndGameId } from '../../services/PlayerMatchService';
import { PlayerMatch } from '../../models/PlayerMatch';
import VetoComponent from './Veto/Veto';
import RoundResults from './RoundResults';
import useLocalStorage from 'use-local-storage'; // Import useLocalStorage
import { mapMappings } from '../../Constants';


const MatchPage = () => {
    const { matchId } = useParams<{ matchId: string }>();
    const [isLoading, setIsLoading] = useState(true);
    const [matches, setMatches] = useState<Match[] | null>(null);
    const [currentGame, setCurrentGame] = useState<Match | null>(null);
    const [team1Players, setTeam1Players] = useState<PlayerMatch[]>([]);
    const [team2Players, setTeam2Players] = useState<PlayerMatch[]>([]);
    const [isDark] = useLocalStorage<boolean>("isDark", false); // Retrieve isDark

    const fetchData = useCallback(async () => {
        try {
            if (!matchId) throw new Error('No matchId provided');
            const matchesData = await getMatchById(matchId);

            if (!matchesData) throw new Error('No data found');

            setMatches(matchesData);

        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }, [matchId]);

    const fetchTeamPlayers = useCallback(async (playerIds: string[], setPlayers: Dispatch<SetStateAction<PlayerMatch[]>>) => {
        if (!currentGame) return;
        const players = await Promise.all(playerIds.map(async (steamId) => {
            try {
                const player = await getPlayerMatchBySteamIdAndGameId(steamId, currentGame.gameid);
                return player || null;
            }
            catch (error) {
                console.error(error);
                return null;
            }
        }));
        setPlayers(players.filter(player => player !== null) as PlayerMatch[]);
    }, [currentGame]);

    const fetchAllTeamPlayers = useCallback(
        async (playerIds: string[], setPlayers: Dispatch<SetStateAction<PlayerMatch[]>>) => {
            if (!matches) return;

            // Create a dictionary to store player statistics
            const playerStatsMap: Record<string, PlayerMatch> = {};

            let counter = 0;
            for (const match of matches) {
                if (match.team1score === 0 && match.team2score === 0) {
                    continue;
                }
                counter++;
                for (const steamId of playerIds) {
                    try {
                        const player = await getPlayerMatchBySteamIdAndGameId(steamId, match.gameid);
                        if (player) {
                            // If player exists in the dictionary, add statistics; otherwise, initialize it
                            if (playerStatsMap[steamId]) {
                                playerStatsMap[steamId].kills += player.kills;
                                playerStatsMap[steamId].deaths += player.deaths;
                                playerStatsMap[steamId].assists += player.assists;
                                playerStatsMap[steamId].adr += player.adr;
                                playerStatsMap[steamId].kast += player.kast;
                                playerStatsMap[steamId].rating2 += player.rating2;
                            } else {
                                playerStatsMap[steamId] = { ...player };
                            }
                        }
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            }

            // Convert the dictionary values back to an array
            const aggregatedPlayers = Object.values(playerStatsMap);
            // divide adr, kast and rating stats by the number of matches
            aggregatedPlayers.forEach(player => {
                player.adr = Math.round((player.adr / counter) * 100) / 100;
                player.kast = Math.round((player.kast / counter) * 100) / 100;
                player.rating2 = Math.round((player.rating2 / counter) * 100) / 100;
            });
            setPlayers(aggregatedPlayers);
        },
        [matches]
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const removeDuplicates = (playerIds: string[]) => {
        const uniquePlayerIds = playerIds.filter((steamId, index) => playerIds.indexOf(steamId) === index);
        return uniquePlayerIds;
    }

    useEffect(() => {
        if (currentGame) {
            fetchTeamPlayers(currentGame.team1playerids || [], setTeam1Players);
            fetchTeamPlayers(currentGame.team2playerids || [], setTeam2Players);
        }
        else {
            if (!matches) return;
            const team1PlayerIds = matches.map(match => match.team1playerids || []).flat();
            const team2PlayerIds = matches.map(match => match.team2playerids || []).flat();

            const unique1PlayerIds = Array.from(removeDuplicates(team1PlayerIds));
            const unique2PlayerIds = Array.from(removeDuplicates(team2PlayerIds));

            if (unique1PlayerIds.length > 0 && unique2PlayerIds.length > 0) {
                fetchAllTeamPlayers(unique1PlayerIds, setTeam1Players);
                fetchAllTeamPlayers(unique2PlayerIds, setTeam2Players);
            }
        }
    }, [currentGame, fetchAllTeamPlayers, fetchTeamPlayers, matches]);

    if (isLoading) return (
        <div className={`container text-center ${isDark ? 'text-light bg-dark' : ''}`}>
            <Loading />
        </div>
    );

    if (!matches || !matchId) return <div>No data available.</div>;

    return (
        <div className={`match-stats-page ${isDark ? 'bg-dark text-light' : ''}`}>
            <MatchDetail matches={matches} />

            {matches.length > 1 && (
                <div className="d-flex align-items-start justify-content-center">
                    <button
                        className="btn btn-primary btn-lg m-1"
                        onClick={() => setCurrentGame(null)}
                        disabled={currentGame === null}
                    >
                        Samlet
                    </button>

                    {matches.map((match) => (
                        <button
                            className="btn btn-primary btn-lg m-1"
                            key={match.gameid}
                            onClick={() => setCurrentGame(match)}
                            disabled={currentGame?.gameid === match.gameid}
                        >
                            {mapMappings.get(match.mapName)}
                        </button>
                    ))}
                </div>
            )}

            <Scoreboard team={matches[0].team1} players={team1Players} teamScore={matches.length === 1 ? matches[0].team1score : currentGame?.team1score} />
            {matches.length > 1 ? (
                currentGame !== null && currentGame.roundEnds && (
                    <div className="round-results-section">
                        <RoundResults
                            roundEnds={currentGame.roundEnds}
                            team1Id={currentGame.team1.teamId}
                            team2Id={currentGame.team2.teamId}
                        />
                    </div>
                )
            ) : (
                <div className="round-results-section">
                    {matches[0].roundEnds && (
                        <RoundResults
                            roundEnds={matches[0].roundEnds}
                            team1Id={matches[0].team1.teamId}
                            team2Id={matches[0].team2.teamId}
                        />
                    )}
                </div>
            )}
            <Scoreboard team={matches[0].team2} players={team2Players} teamScore={matches.length === 1 ? matches[0].team2score : currentGame?.team2score} />

            <hr />

            {matches[0].vetoLog !== null && (
                <>
                    <h2 className='text-center'>VETO</h2>
                    <div className='d-flex justify-content-center'>
                        <VetoComponent vetoString={matches[0].vetoLog} season={matches[0].season} />
                    </div>
                    <hr />
                </>
            )}

            {team1Players.length > 0 && team2Players.length > 0 && (
                <div>
                    <h2 className='text-center'>Lineups</h2>
                    <TeamLineup matchId={matchId} team={matches[0].team1} players={team1Players} />
                    <TeamLineup matchId={matchId} team={matches[0].team2} players={team2Players} />
                </div>
            )}
        </div>
    );
};

export default MatchPage;
