import { useNavigate, useParams } from "react-router-dom";
import { Account } from "../../models/Account";
import { fetchAccount, updateAccount } from "../../services/AccountService";
import { useState, useEffect, useContext } from "react";
import '../../styles/theme.css';
import { AuthContext } from "../../context/AuthContext";
import { admins, roles } from "../../Constants";


const EditPlayerPage = () => {
    const { steamId } = useParams();
    const { user: visitor, token } = useContext(AuthContext);
    const [player, setPlayer] = useState<Account | null>(null);
    const [editPermission, setEditPermission] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPlayer = async () => {
            if (!steamId) return;
            try {
                const data = await fetchAccount(steamId);
                if (data) {
                    setPlayer(data);
                }
            } catch (error) {
                console.error('Error fetching player data:', error);
                setPlayer(null);
            }
        };

        fetchPlayer();
    }, [steamId]);

    useEffect(() => {
        if (!visitor) return;
        if (admins.includes(Number(visitor.steamID))) {
            setIsAdmin(true);
        }
    }, [visitor]);

    useEffect(() => {
        if (!visitor) return;
        if (visitor.steamID === steamId || isAdmin) {
            setEditPermission(true);
        }
    }, [visitor, steamId, isAdmin]);

    const handleChange = (e: any) => {
        const { id, checked } = e.target;
        if (!player) return;
        if (id === "flexSwitchCheckDefault") {
            setPlayer({ ...player, lft: checked });
        }
    };

    const savePlayer = async (player: Account) => {
        try {
            if (!token) {
                alert("Du skal være logget ind for at ændre din side");
                navigate(`/player/${steamId}`);
                return;
            }
            await updateAccount(player, token);
            alert("Dine ændringer er gemt");
            navigate(`/player/${steamId}`);
        }
        catch (error) {
            alert("Der skete en fejl");
        }
    }

    const validateSocialMediaInputs = (twitter: string, twitch: string, youtube: string, instagram: string) => {
        if (twitter.includes("twitter.com") || twitter.includes("x.com")) {
            alert("Indtast venligst kun dit Twitter brugernavn, ikke hele linket.");
            return false;
        }
        if (twitch.includes("twitch.tv")) {
            alert("Indtast venligst kun dit Twitch brugernavn, ikke hele linket.");
            return false;
        }
        if (youtube.includes("youtube.com")) {
            alert("Indtast venligst kun dit YouTube brugernavn, ikke hele linket.");
            return false;
        }
        if (instagram.includes("instagram.com")) {
            alert("Indtast venligst kun dit Instagram brugernavn, ikke hele linket.");
            return false;
        }
        return true;
    }
    

    const saveChanges = () => {
        if (!player) {
            return;
        }
        const lft = document.getElementById("flexSwitchCheckDefault") as HTMLInputElement;
        const twitter = document.getElementById("twitter-url") as HTMLInputElement;
        const twitch = document.getElementById("twitch-url") as HTMLInputElement;
        const youtube = document.getElementById("youtube-url") as HTMLInputElement;
        const instagram = document.getElementById("instagram-url") as HTMLInputElement;
        const role = document.getElementById("role-select") as HTMLInputElement;
        const name = document.getElementById("name-input") as HTMLInputElement;
        const age = document.getElementById("age-input") as HTMLInputElement;
        const donator = document.getElementById("donator") as HTMLInputElement;
        const premium = document.getElementById("premium") as HTMLInputElement;
        lft.checked ? player.lft = true : player.lft = false;
        player.twitter = twitter.value || "";
        player.twitch = twitch.value || "";
        player.youtube = youtube.value || "";
        player.instagram = instagram.value || "";
        player.role = role.value || "";
        player.name = name.value || "";
        player.age = parseInt(age.value) || 0;
        if (isAdmin) {
            player.donator = donator.checked;
            player.premium = premium.checked;
        }
    
        if (!validateSocialMediaInputs(player.twitter, player.twitch, player.youtube, player.instagram)) {
            return;
        }
    
        if (player.lft && !player.role && !player.age) {
            alert("Du skal vælge en rolle og skrive din alder");
            return;
        }
        savePlayer(player);
    }
    

    if (!player) {
        return <div><h1>Ændre din side</h1><p>Der skete en fejl</p></div>;
    }

    if (!editPermission) {
        return <div><h1>Ændre din side</h1><p>Du har ikke adgang til denne side</p></div>;
    }

    return (
        <div className="container">
            <h1>Ændre din side</h1>
            <hr />
            <div className="row">
                <div className="col-12 col-md-6">
                    <h2>Konto indstillinger</h2>
                    <div className="mb-3">
                        <h3>LFT</h3>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={player.lft} onChange={handleChange} />
                        </div>
                        <h3>Ændre rolle</h3>
                        <div className="mb-3">
                            <select className="form-select" id="role-select" defaultValue={player.role ? player.role : ""}>
                                <option value="" disabled>Vælg en rolle</option>
                                {
                                    roles.map((role) => {
                                        return (
                                            <option value={role} key={role}>{role}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        {
                            isAdmin && (
                                <div className="mb-3">
                                    <h3>Donator</h3>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="donator" defaultChecked={player.donator} />
                                    </div>
                                    <h3>Premium</h3>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="premium" defaultChecked={player.premium} />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <h2>Personlige oplysninger</h2>
                    <div className="mb-3">
                        <h3>Navn</h3>
                        <div className="mb-3">
                            <input type="text" className="form-control" id="name-input" defaultValue={player.name ? player.name : ""} />
                        </div>
                        <h3>Alder</h3>
                        <div className="mb-3">
                            <input type="number" className="form-control" id="age-input" defaultValue={player.age ? player.age : ""} />
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="col-12 col-md-6">
                    <h2>Sociale medier</h2>
                    <div className="mb-3">
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon3">https://twitter.com/</span>
                            {
                                player.twitter ?
                                    (
                                        <input type="text" className="form-control" id="twitter-url" aria-describedby="basic-addon3" defaultValue={player.twitter} />
                                    ) : (
                                        <input type="text" className="form-control" id="twitter-url" aria-describedby="basic-addon3" />
                                    )
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon3">https://www.twitch.tv/</span>
                            {
                                player.twitch ?
                                    (
                                        <input type="text" className="form-control" id="twitch-url" aria-describedby="basic-addon3" defaultValue={player.twitch} />
                                    ) : (
                                        <input type="text" className="form-control" id="twitch-url" aria-describedby="basic-addon3" />
                                    )
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon3">https://www.youtube.com/@</span>
                            {
                                player.youtube ?
                                    (
                                        <input type="text" className="form-control" id="youtube-url" aria-describedby="basic-addon3" defaultValue={player.youtube} />
                                    ) : (
                                        <input type="text" className="form-control" id="youtube-url" aria-describedby="basic-addon3" />
                                    )
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon3">https://www.instagram.com/</span>
                            {
                                player.instagram ?
                                    (
                                        <input type="text" className="form-control" id="instagram-url" aria-describedby="basic-addon3" defaultValue={player.instagram} />
                                    ) : (
                                        <input type="text" className="form-control" id="instagram-url" aria-describedby="basic-addon3" />
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <h2>Gem ændringer</h2>
            <div className="mb-3">
                <button type="button" className="btn btn-primary" onClick={saveChanges}>Gem ændringer</button>
            </div>
        </div>
    );
}

export default EditPlayerPage;