import axios from 'axios';
import { baseURL } from '../Constants';
import { PlayerStats } from '../models/PlayerStats';
import { LeaderboardPlayer } from '../models/LeaderboardPlayer';
import { SeasonPlayerRanking } from '../types/types';
import { forEach } from 'lodash';

const url = `${baseURL}/PlayerStat`;

const roundPlayerStats = (player: PlayerStats): PlayerStats => {
    const roundedPlayer: PlayerStats = {
        ...player,
        kills: Math.round(player.kills * 100) / 100,
        hsKills: Math.round(player.hsKills * 100) / 100,
        deaths: Math.round(player.deaths * 100) / 100,
        assists: Math.round(player.assists * 100) / 100,
        hs: Math.round(player.hs * 100) / 100,
        dmg: Math.round(player.dmg * 100) / 100,
        adr: Math.round(player.adr * 100) / 100,
        kd: Math.round(player.kd * 100) / 100,
        fk: Math.round(player.fk * 100) / 100,
        kast: Math.round(player.kast * 100) / 100,
        hltv1Rating: Math.round(player.hltv1Rating * 100) / 100,
        hltv2Rating: Math.round(player.hltv2Rating * 100) / 100,
        openingDuelWinPercentage: Math.round(player.openingDuelWinPercentage * 100) / 100,
        openingDuelAttemptPercentage: Math.round(player.openingDuelAttemptPercentage * 100) / 100,
        enemyFlashTime: Math.round(player.enemyFlashTime * 100) / 100,
        timeFlashed: Math.round(player.timeFlashed * 100) / 100,
        utilityDamage: Math.round(player.utilityDamage * 100) / 100,
        gamesPlayed: Math.round(player.gamesPlayed * 100) / 100,
    };
    return roundedPlayer;
}


const roundPlayersStats = (playersData: PlayerStats[]): PlayerStats[] => {
    if (playersData === undefined) {
        return [];
    }
    const roundedPlayers = playersData.map((player: PlayerStats) => {
        return roundPlayerStats(player);
    });

    return roundedPlayers;
}

export const fetchTop10Players = async (season: number, steamId: string | null, division: string | null, group: string | null): Promise<{ [stat: string]: LeaderboardPlayer[] }> => {
    try {
        let completeUrl = `${url}/leaderboard?season=${season}`
        if (steamId !== null) {
            completeUrl += `&steamId=${steamId}`
        }
        if (division !== null) {
            completeUrl += `&division=${division}`
        }
        if (group !== null) {
            completeUrl += `&group=${group}`
        }
        const response = await axios.get(completeUrl);
        const leaderboardPlayers = response.data as { [stat: string]: LeaderboardPlayer[] };

        // round every leaderboard player stats
        forEach(leaderboardPlayers, (players: LeaderboardPlayer[]) => {
            players.forEach((player: LeaderboardPlayer) => {
                player.stats = roundPlayerStats(player.stats);
            });
        });


        return leaderboardPlayers;
    }
    catch (error) {
        throw error;
    }
}

export const fetchPlayersByDivision = async (division: string): Promise<PlayerStats[]> => {
    try {
        const response = await axios.get(`${url}/playersByDivision/${division}`);
        return roundPlayersStats(response.data);
    }
    catch (error) {
        throw error;
    }
}

export const fetchSeasonsPlayed = async (steamId: string): Promise<number[]> => {
    try {
        const response = await axios.get(`${url}/getSeasonsPlayed/${steamId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const fetchAllSeasonPlayerRankings = async (steamId: string): Promise<SeasonPlayerRanking[]> => {
    try {
        const response = await axios.get(`${baseURL}/PlayerStat/player-all-season-rankings`, {
            params: { steamId }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch all season player rankings:', error);
        throw error;
    }
}

export const fetchPlayerBySteamIdAndSeason = async (steamId: string, season: number): Promise<PlayerStats | undefined> => {
    try {
        const response = await axios.get(`${url}/getPlayerBySeasonAndSteamId`, {
            params: {
                steamId,
                season
            }
        });

        return roundPlayerStats(response.data);
    }
    catch (error) {
        throw error;
    }
}

export const fetchPlayersByTeamId = async (teamId: string, season: number): Promise<PlayerStats[]> => {
    try {
        const response = await axios.get(`${url}/getPlayersByTeamId?teamid=${teamId}&season=${season}`);
        return roundPlayersStats(response.data);
    }
    catch (error) {
        throw error;
    }
}