import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Match } from "../../models/Match";
import { getMatchesForPlayerBySeason } from "../../services/MatchService";
import MatchTable from "../../components/MatchTabel";
import { fetchSeasonsPlayed } from "../../services/PlayerStatService";
import SeasonScroll from "../../components/SeasonScroll/SeasonScroll";
import Loading from "../../components/Loading";

const PlayerMatch = () => {
    const { steamId } = useParams<{ steamId: string }>();
    const [matches, setMatches] = useState<Match[]>([]);
    const [loading, setLoading] = useState(true);
    const [seasons, setSeasons] = useState<number[]>([]);
    const [season, setSeason] = useState<number>(0);

    const fetchPlayedSeasons = useCallback(async () => {
        if (!steamId) return;
        try {
            const fetchedSeasons = await fetchSeasonsPlayed(steamId);
            const sortedSeasons = fetchedSeasons.sort((a, b) => b - a);
            setSeasons(sortedSeasons);
            setSeason(Math.max(...sortedSeasons));
        } catch (error) {
            console.error(error);
            return null;
        } finally {
            setLoading(false);
        }
    }, [steamId]);

    useEffect(() => {
        fetchPlayedSeasons();
    }, [fetchPlayedSeasons]);

    useEffect(() => {
        if (!steamId || !season) return;
        setLoading(true); // Set loading to true when starting a new fetch
        const fetchMatches = async () => {
            try {
                const matches = await getMatchesForPlayerBySeason(steamId, season);
                setMatches(matches);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false); // Set loading to false when the fetch is done
            }
        };
        fetchMatches();
    }, [season, steamId])

    return (
        <div>
            {
                seasons.length > 0 && (
                    <SeasonScroll seasons={seasons} selectedSeason={season} onSeasonChange={setSeason} />
                )
            }
            {
                loading ? (
                    <Loading />
                ) : (
                    <MatchTable matches={matches} compact={false} />
                )
            }
        </div>
    );

}

export default PlayerMatch;