import { useEffect, useState, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { getTeamsUsingPlayer, getAccountBySteamId } from "../../services/FantasyService"; // Import the account fetcher
import Loading from "../../components/Loading";
import { FantasyTeam } from "../../models/FantasyTeam";

const TeamsUsingPlayerPage = () => {
    const { steamID, season } = useParams<{ steamID: string; season: string }>();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');
    const [teams, setTeams] = useState<FantasyTeam[]>([]);
    const [playerName, setPlayerName] = useState<string>('Unknown Player'); // State to store the player's name

    // Fetch player account details using steamID
    const fetchPlayerName = useCallback(async () => {
        try {
            const account = await getAccountBySteamId(steamID!); // Fetch the player's account data
            setPlayerName(account.playerName); // Set the player's name
        } catch (err) {
            setError("Failed to fetch player details");
        }
    }, [steamID]);

    const fetchTeams = useCallback(async () => {
        if (!steamID || !season) {
            setError("Invalid parameters.");
            setLoading(false);
            return;
        }

        try {
            const teams = await getTeamsUsingPlayer(steamID, Number(season));
            console.log("Fetched teams:", teams);

            // Sort teams by points (descending order)
            teams.sort((a, b) => b.points - a.points);

            setTeams(teams);
        } catch (err) {
            setError(err instanceof Error ? err.message : "An unexpected error occurred");
        } finally {
            setLoading(false);
        }
    }, [steamID, season]);

    // Fetch both player name and teams on component mount
    useEffect(() => {
        fetchPlayerName();  // Fetch the player's name
        fetchTeams();       // Fetch the teams
    }, [fetchPlayerName, fetchTeams]);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <div className="text-center"><p>{error}</p></div>;
    }

    if (!teams.length) {
        return <div className="text-center"><p>No teams found for this player in this season.</p></div>;
    }

    return (
        <div className="container text-center">
            {/* Use the fetched player's name in the title */}
            <h1>Teams Using {playerName} in Season {season}</h1>
            <div className="row">
                {teams.map((team) => (
                    <div className="col-12 col-md-4 mb-4" key={team.id}>
                        <div className="card p-3">
                            {/* Display team owner info */}
                            <h5>{team?.account?.playerName || 'Unknown'}'s Team in {team.division}</h5>
                            <p>Points: {team.points}</p>
                            <Link to={`/fantasy/view/${team.account.steamID}/${team.season}/${team.division}`} className="btn btn-primary">
                                View Team
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TeamsUsingPlayerPage;
