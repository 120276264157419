import { useState, useEffect, useMemo } from "react";
import { Team } from "../models/Team";
import { fetchTeamByTeamIdAndSeason } from "../services/TeamService";
import { PlayerStats } from "../models/PlayerStats";
import { fetchPlayersByTeamId } from "../services/PlayerStatService";

const useTeamData = (teamId: string | undefined, season: number | undefined) => {
    const [teamData, setTeamData] = useState<Team | null>(null);
    const [allPlayers, setAllPlayers] = useState<PlayerStats[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        if (!teamId || !season) return;
        setLoading(true);
        fetchTeam(teamId, season)
            .then(data => setTeamData(data))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [season, teamId]);

    useEffect(() => {
        if (!teamData || !season) return;
        setLoading(true);
        fetchPlayers(teamData.teamId, season)
            .then(data => setAllPlayers(data))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [season, teamData]);

    const fetchTeam = async (teamId: string, season: number) => {
        if (!teamId) return null;
        try {
            const data = await fetchTeamByTeamIdAndSeason(teamId, season);
            if (!data) throw new Error('Team not found');
            return data;
        } catch (err) {
            throw err;
        }
    };

    const fetchPlayers = async (teamId: string, season: number) => {
        try {
            const data = await fetchPlayersByTeamId(teamId, season);
            if (!data) throw new Error('Players not found');
            return data;
        } catch (err) {
            throw err;
        }
    };

    const players = useMemo(() => {
        return allPlayers
            .sort((a, b) => b.hltv2Rating - a.hltv2Rating);
    }, [allPlayers]);

    return { teamData, players, loading, error };
};

export default useTeamData;
