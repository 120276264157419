import { useCallback, useEffect, useState } from 'react';
import { fetchSeasonsPlayed } from '../../services/PlayerStatService';
import usePlayerStats from '../../hooks/usePlayerStats';
import { useParams } from 'react-router-dom';
import SeasonScroll from '../../components/SeasonScroll/SeasonScroll';
import Loading from '../../components/Loading';

type Params = {
    steamId: string;
};

const PlayerStatsDisplay: React.FC = () => {
    const { steamId } = useParams<Params>();
    const [seasons, setSeasons] = useState<number[]>([]);
    const [season, setSeason] = useState<number | null>(null);
    const { stats, loading } = usePlayerStats(steamId, season);

    const fetchPlayedSeasons = useCallback(async () => {
        if (!steamId) return;
        try {
            const fetchedSeasons = await fetchSeasonsPlayed(steamId);
            const sortedSeasons = fetchedSeasons.sort((a, b) => b - a);
            setSeasons(sortedSeasons);
            setSeason(Math.max(...sortedSeasons));
        } catch (error) {
            console.error(error);
        }
    }, [steamId]);

    useEffect(() => {
        fetchPlayedSeasons();
    }, [fetchPlayedSeasons]);

    const handleSeasonChange = (selectedSeason: number) => {
        setSeason(selectedSeason);
    };

    const renderStats = (label: string, value: number) => (
        <div className="row">
            <div className="col-6">
                <h6>{label}</h6>
            </div>
            <div className="col-6">
                {value}
            </div>
        </div>
    );


    if (loading) {
        return (
            <Loading />
        );
    }

    if (!stats || !season) {
        return (
            <div className="container text-center">
                <h1>Ingen statistik fundet</h1>
            </div>
        )
    }

    return (
        <div className="container">
            <SeasonScroll seasons={seasons} selectedSeason={season} onSeasonChange={handleSeasonChange} />
            <div className="row text-center">
                <div className="col-6 col-md-3">
                    <h6>Kampe spillet</h6>
                    <h2>{stats.gamesPlayed}</h2>
                </div>
                <div className="col-6 col-md-3">
                    <h6>Rating 2.0</h6>
                    <h2>{stats.hltv2Rating}</h2>
                </div>
                <div className="col-6 col-md-3">
                    <h6>HS%</h6>
                    <h2>{stats.hs}</h2>
                </div>
                <div className="col-6 col-md-3">
                    <h6>K/D</h6>
                    <h2>{stats.kd}</h2>
                </div>
                <div className="col-6 col-md-3">
                    <h6>ADR</h6>
                    <h2>{stats.adr}</h2>
                </div>
                <div className="col-6 col-md-3">
                    <h6>KAST%</h6>
                    <h2>{stats.kast}</h2>
                </div>
                <div className="col-6 col-md-3">
                    <h6>OP Duel Win%</h6>
                    <h2>{stats.openingDuelWinPercentage}</h2>
                </div>
                <div className="col-6 col-md-3">
                    <h6>OP Duel Attemps%</h6>
                    <h2>{stats.openingDuelAttemptPercentage}</h2>
                </div>
                <div className="col-6 col-md-3">
                    <h6>Util DMG</h6>
                    <h2>{stats.utilityDamage}</h2>
                </div>
                <div className="col-6 col-md-3">
                    <h6>Enemies Flashed </h6>
                    <h2>{stats.enemiesFlashed}</h2>
                </div>
                <div className="col-6 col-md-3">
                    <h6>Time Flashed (Alpha)</h6>
                    <h2>{stats.timeFlashed}</h2>
                </div>
                <div className="col-6 col-md-3">
                    <h6>Enemy Time Flashed (Alpha)</h6>
                    <h2>{stats.enemyFlashTime}</h2>
                </div>
                {
                    season >= 26 ? <div className="col-6 col-md-3">
                        <h6>AWP kills</h6>
                        <h2>{stats.awpKills}</h2>
                    </div> : null
                }
            </div>
            <hr />
            <div className="col-md-12 text-center">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <h1>Total</h1>
                        {renderStats("Kills", stats.kills)}
                        {renderStats("Deaths", stats.deaths)}
                        {renderStats("Assists", stats.assists)}
                        {renderStats("FK", stats.fk)}
                        {renderStats("HS kills", stats.hsKills)}
                        {renderStats("Damage", stats.dmg)}
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <h1>Per kamp</h1>
                        {renderStats("Kills", Math.round((stats.kills / stats.gamesPlayed) * 100) / 100)}
                        {renderStats("Deaths", Math.round((stats.deaths / stats.gamesPlayed) * 100) / 100)}
                        {renderStats("Assists", Math.round((stats.assists / stats.gamesPlayed) * 100) / 100)}
                        {renderStats("FK", Math.round((stats.fk / stats.gamesPlayed) * 100) / 100)}
                        {renderStats("HS kills", Math.round((stats.hsKills / stats.gamesPlayed) * 100) / 100)}
                        {renderStats("Damage", Math.round((stats.dmg / stats.gamesPlayed) * 100) / 100)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlayerStatsDisplay;
