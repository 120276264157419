import { useCallback, useState } from "react";
import InfiniteScroll from 'react-infinite-scroller';
import Loading from "./Loading";
import { Match } from "../models/Match";
import { getLatestMatches } from "../services/MatchService";
import MatchTable from "./MatchTabel";

// Create a new mapping for simplified division selection
const simpleDivisionMappings = new Map([
    ["ligaen", "Ligaen"],
    ["1div", "1 Division"],
    ["2div", "2 Division"],
    ["3div", "3 Division"],
    ["4div", "4 Division"],
    ["5div", "5 Division"]
]);

interface RecentMatchesProps {
    compact: boolean;
}

const RecentMatches: React.FC<RecentMatchesProps> = ({ compact }) => {
    const [matchesByDate, setMatchesByDate] = useState<{ [date: string]: Match[] }>({});
    const [matchesByDivision, setMatchesByDivision] = useState<{ [division: string]: Match[] }>({});
    const [selectedDivision, setSelectedDivision] = useState<string>("all");
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState(false);
    const [page, setPage] = useState(0);

    const fetchData = useCallback(async () => {
        try {
            const newMatches = await getLatestMatches(page);
            if (!newMatches || newMatches.length === 0) {
                setHasMore(false);
                return;
            }
            const newMatchesWithTeams = await Promise.all(newMatches.map(async (match) => {
                const team1 = match.team1;
                const team2 = match.team2;
                return { ...match, team1, team2 };
            }));

            // Group matches by date and division
            const updatedMatchesByDate = { ...matchesByDate };
            const updatedMatchesByDivision = { ...matchesByDivision };
            newMatchesWithTeams.forEach(match => {
                const matchDate = match.match_time.split('T')[0]; // Extract date part
                updatedMatchesByDate[matchDate] = updatedMatchesByDate[matchDate] || [];
                if (!updatedMatchesByDate[matchDate].find(m => m.matchid === match.matchid)) {
                    updatedMatchesByDate[matchDate].push(match);
                }

                const divisions = new Set<string>();
                divisions.add(match.team1.division);
                divisions.add(match.team2.division);

                divisions.forEach(division => {
                    const simplifiedDivision = division.match(/^(\d+div)/)?.[1] || division;
                    updatedMatchesByDivision[simplifiedDivision] = updatedMatchesByDivision[simplifiedDivision] || [];
                    updatedMatchesByDivision[simplifiedDivision].push(match);
                });
            });

            setMatchesByDate(updatedMatchesByDate);
            setMatchesByDivision(updatedMatchesByDivision);
            setPage(page + 1);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(true);
        }
    }, [matchesByDate, matchesByDivision, page]);

    return (
        <div>
            <div className="dropdown mb-1 text-center">
                <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {selectedDivision === "all" ? "Alle divisioner" : simpleDivisionMappings.get(selectedDivision)}
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <button className="dropdown-item" onClick={() => setSelectedDivision("all")}>
                            Alle divisioner
                        </button>
                    </li>
                    {
                        Array.from(simpleDivisionMappings.keys()).map((division, index) => (
                            <li key={index}>
                                <button className="dropdown-item" onClick={() => setSelectedDivision(division)}>
                                    {simpleDivisionMappings.get(division)}
                                </button>
                            </li>
                        ))
                    }
                </ul>
            </div>
            {
                error ? <div className="text-center">Der skete en fejl ved hentning af data</div> : (
                    <InfiniteScroll
                        pageStart={page}
                        loadMore={fetchData}
                        hasMore={hasMore}
                        loader={<Loading key={0} />}
                    >
                        {
                            Object.keys(matchesByDate).map((date, index) => {
                                const matchesForDate = matchesByDate[date];
                                const filteredMatches = selectedDivision === "all" ? matchesForDate :
                                    matchesByDivision[selectedDivision]?.filter(match => match.match_time.split('T')[0] === date) || [];
                                return (
                                    <div className="text-center" key={index}>
                                        <h2>{date}</h2>
                                        <MatchTable matches={filteredMatches} compact={false} />
                                    </div>
                                );
                            })
                        }
                    </InfiniteScroll>
                )
            }

        </div>
    );
};

export default RecentMatches;
