import { useCallback, useEffect, useState } from "react";
import { simpleDivisionMappings, roles } from "../../Constants";
import RangeSlider from "../../components/react-range-slider-input/dist";
import '../../components/react-range-slider-input/dist/style.css';

interface FiltersProps {
    posts: any[];
    setFilteredPosts: (posts: any[]) => void;
}

const Filters: React.FC<FiltersProps> = ({ posts, setFilteredPosts }) => {
    const [eloRangeLimits, setEloRangeLimits] = useState<[number, number]>([0, 5000]);
    const [eloRange, setEloRange] = useState<[number, number]>([1500, 5000]);
    const [selectedDivisions, setSelectedDivisions] = useState<string[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

    useEffect(() => {
        const minElo = Math.min(...posts.map(post => post.minELO));
        const maxElo = Math.max(...posts.map(post => post.minELO));
        setEloRangeLimits([minElo, maxElo]);
        setEloRange([minElo, maxElo]);
    }, [posts]);

    const sortByDivision = useCallback((posts: any[]) => {
        posts.sort((a, b) => {
            const aDivision = simpleDivisionMappings.get(a.minDivisionRequirements) || '';
            const bDivision = simpleDivisionMappings.get(b.minDivisionRequirements) || '';
            return aDivision.localeCompare(bDivision);
        });
        return posts;
    }, []);

    const applyFilters = useCallback(() => {

        let filteredPosts = posts.filter(post => {
            const division = simpleDivisionMappings.get(post.minDivisionRequirements)?.split(" ").slice(0, 2).join(" ") || '';

            return post.minELO >= eloRange[0] && post.minELO <= eloRange[1] &&
                (selectedDivisions.length === 0 || selectedDivisions.includes(division)) &&
                (selectedRoles.length === 0 || selectedRoles.some(role => post.roleRequirements.includes(role)));
        });

        filteredPosts = sortByDivision(filteredPosts);
        setFilteredPosts(filteredPosts);
    }, [eloRange, posts, selectedDivisions, selectedRoles, setFilteredPosts, sortByDivision]);

    const handleDivisionCheckboxChange = useCallback((division: string) => {
        if (selectedDivisions.includes(division)) {
            setSelectedDivisions(selectedDivisions.filter(d => d !== division));
        } else {
            setSelectedDivisions([...selectedDivisions, division]);
        }
    }, [selectedDivisions]);

    const handleRoleCheckboxChange = useCallback((role: string) => {
        if (selectedRoles.includes(role)) {
            setSelectedRoles(selectedRoles.filter(d => d !== role));
        } else {
            setSelectedRoles([...selectedRoles, role]);
        }
    }, [selectedRoles]);

    const getDivisions = useCallback((divisions: Map<string, string>): string[] => {
        let divisionSet = new Set<string>();
        divisions.forEach((value) => {
            divisionSet.add(value.split(" ").slice(0, 2).join(" "));
        });
        const divisionArray = Array.from(divisionSet);
        return divisionArray;
    }, []);

    useEffect(() => {
        applyFilters();
    }, [eloRange, applyFilters]);

    return (
        <div>
            <h5>Filtre</h5>
            <div className="mb-3">
                <h6>ELO</h6>
                <div className="text-center mb-2">
                    {
                        eloRange[0] === eloRangeLimits[0] && eloRange[1] === eloRangeLimits[1] ? "Alle" : `${eloRange[0]} - ${eloRange[1]}`
                    }
                </div>
                <RangeSlider value={eloRange} onInput={setEloRange} min={eloRangeLimits[0]} max={eloRangeLimits[1]} rangeSlideDisabled={true} />
            </div>
            <div className="mb-3">
                <h6>Division</h6>
                <div className="row">
                    {getDivisions(simpleDivisionMappings).map((division, index) => (
                        <div className="col-12 col-sm-6 col-md-6" key={index}>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id={division}
                                    checked={selectedDivisions.includes(division)}
                                    onChange={() => handleDivisionCheckboxChange(division)}
                                />
                                <label className="form-check-label" htmlFor={division}>
                                    {division}
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="mb-3">
                <h6>Rolle</h6>
                <div className="row">
                    {
                        roles.map((role, index) => (
                            <div className="col-12 col-sm-6 col-md-6" key={index}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id={role} checked={selectedRoles.includes(role)} onChange={() => handleRoleCheckboxChange(role)} />
                                    <label className="form-check-label" htmlFor={role}>
                                        {role}
                                    </label>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Filters;
