import React, { useState } from 'react';

interface Tab {
    id: string;
    title: string;
}

interface TabsComponentProps {
    onTabChange: (tabId: string) => void;
}

const tabs: Tab[] = [
    { id: 'players', title: 'Spillere' },
    { id: 'matches', title: 'Kampe' },
    { id: 'veto', title: 'Vetos' }
];

const TabItem: React.FC<{ tab: Tab; isActive: boolean; onClick: (id: string) => void }> = ({ tab, isActive, onClick }) => (
    <li className="nav-item" role="presentation">
        <button
            className={`nav-link ${isActive ? 'active' : ''} m-1`}
            id={`pills-${tab.id}-tab`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-${tab.id}`}
            type="button"
            role="tab"
            aria-controls={tab.id}
            aria-selected={isActive}
            onClick={() => onClick(tab.id)}
        >
            {tab.title}
        </button>
    </li>
);

const TabsComponent: React.FC<TabsComponentProps> = ({ onTabChange }) => {
    const [activeTab, setActiveTab] = useState<string>("players");

    const handleTabClick = (tabId: string) => {
        setActiveTab(tabId);
        onTabChange(tabId); // Calling the callback function passed from the parent
    };

    return (
        <div className="text-center">
            <ul className="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist">
                {tabs.map((tab) => (
                    <TabItem key={tab.id} tab={tab} isActive={tab.id === activeTab} onClick={handleTabClick} />
                ))}
            </ul>
        </div>
    );
};

export default TabsComponent;