import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PlayerStats } from "../models/PlayerStats";
import PlayerImage from "./PlayerImage";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/theme.css';
import useLocalStorage from 'use-local-storage'; // Import useLocalStorage

const PlayerTable = ({ players }: { players: PlayerStats[] }) => {
  const [playerList, setPlayers] = useState<PlayerStats[]>(players);
  const [sortProperty, setSortProperty] = useState<keyof PlayerStats>('hltv2Rating');

  // Retrieve isDark from localStorage
  const [isDark] = useLocalStorage<boolean>("isDark", false);

  useEffect(() => {
    const sortedPlayers = [...players].sort((a, b) => b.hltv2Rating - a.hltv2Rating);
    setPlayers(sortedPlayers);
  }, [players]);

  const sortByProperty = (property: keyof PlayerStats) => {
    const sortedPlayers = [...playerList];
    sortedPlayers.sort((a, b) => Number(b[property]) - Number(a[property]));
    setPlayers(sortedPlayers);
    setSortProperty(property);
  }

  const getButtonLabel = (property: keyof PlayerStats) => {
    switch (property) {
      case 'gamesPlayed':
        return 'Kampe spillet';
      case 'kd':
        return 'K/D';
      case 'hs':
        return 'HS%';
      case 'adr':
        return 'ADR';
      case 'kast':
        return 'KAST%';
      case 'hltv2Rating':
        return 'Rating 2.0';
      default:
        return '';
    }
  }

  return (
    <div className="container">
      <div className="dropdown mb-2">
        <button
          className="btn btn-primary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          defaultValue={sortProperty}
        >
          {getButtonLabel(sortProperty)} <span className="caret" />
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li><button className="dropdown-item" onClick={() => sortByProperty('gamesPlayed')}>Kampe spillet</button></li>
          <li><button className="dropdown-item" onClick={() => sortByProperty('kd')}>K/D</button></li>
          <li><button className="dropdown-item" onClick={() => sortByProperty('hs')}>HS%</button></li>
          <li><button className="dropdown-item" onClick={() => sortByProperty('adr')}>ADR</button></li>
          <li><button className="dropdown-item" onClick={() => sortByProperty('kast')}>KAST%</button></li>
          <li><button className="dropdown-item" onClick={() => sortByProperty('hltv2Rating')}>Rating 2.0</button></li>
        </ul>
      </div>

      <table className={`table table-striped ${isDark ? 'table-dark' : ''}`}>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Spiller</th>
            <th scope="col">Kampe spillet</th>
            <th scope="col">K/D</th>
            <th scope="col">HS%</th>
            <th scope="col">ADR</th>
            <th scope="col">KAST%</th>
            <th scope="col">Rating 2.0</th>
          </tr>
        </thead>
        <tbody>
          {playerList.map((player, index) => (
            <tr key={player.steamID}>
              {isDark ? (
                <td>{index + 1}</td>
              ) : (
                <th scope="row">{index + 1}</th>
              )}
              <td>
                <div className="row text-center">
                  <Link to={`/player/${player.steamID}`} className={`text-decoration-none ${isDark ? 'text-light' : 'text-black'}`}>
                    <div className="col-12">
                      <PlayerImage id={player.steamID} width={75} />
                    </div>
                    <div className="col-12">
                      {player.account ? player.account.playerName : player.steamID}
                    </div>
                  </Link>
                </div>
              </td>
              <td>{player.gamesPlayed}</td>
              <td>{(player.kd).toFixed(2)}</td>
              <td>{(player.hs).toFixed(2)}</td>
              <td>{(player.adr).toFixed(2)}</td>
              <td>{(player.kast).toFixed(2)}</td>
              <td>{(player.hltv2Rating).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PlayerTable;
