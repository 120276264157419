import { useRef, useState } from 'react';
import { PlayerStats } from '../models/PlayerStats';
import PlayerImage from '../components/PlayerImage';
import PlayerTable from '../components/PlayerTable';
import { Link } from 'react-router-dom';
import { Account } from '../models/Account';
import { fetchPlayersByPlayerName } from '../services/AccountService';
import { fetchSeasonsPlayed } from "../services/PlayerStatService";
import { fetchPlayerBySteamIdAndSeason } from "../services/PlayerStatService";
import { debounce } from 'lodash';
import useLocalStorage from 'use-local-storage'; // Import useLocalStorage

type PlayerWithSeason = {
  stats: PlayerStats;
  selectedSeason: number;
};

const ComparePage = () => {
  const [playerSeasons, setPlayerSeasons] = useState<{ [steamId: string]: number[] }>({});
  const [players, setPlayers] = useState<PlayerWithSeason[]>([]);
  const [searchResults, setSearchResults] = useState<Account[]>([]);
  const [error, setError] = useState<any>();
  const inputElem = useRef<HTMLInputElement>(null);
  const handleSearch = debounce(inputVal => fetchSearchResults(inputVal), 500);

  const [isDark] = useLocalStorage<boolean>("isDark", false);

  const fetchSearchResults = async (query: string) => {
    try {
      const results = await fetchPlayersByPlayerName(query);
      setSearchResults(results || []);
      setError('');
    } catch (err) {
      setSearchResults([]);
      setError(err);
    }
  };

  const selectPlayer = async (player: Account) => {
    try {
      const seasons = await fetchSeasonsPlayed(player.steamID);
      const highestSeason = seasons.sort((a, b) => b - a)[0];
      const playerStats = await fetchPlayerBySteamIdAndSeason(player.steamID, highestSeason);

      if (playerStats) {
        setPlayers(prevPlayers => [
          ...prevPlayers,
          { stats: playerStats, selectedSeason: highestSeason }
        ]);
      } else {
        setError(new Error('No stats found for the selected season.'));
      }

      setPlayerSeasons(prevSeasons => ({ ...prevSeasons, [player.steamID]: seasons }));
    } catch (err) {
      setError(err);
    }

    setSearchResults([]);
  };

  const handleSeasonChange = async (steamID: string, season: number) => {
    try {
      const playerStats = await fetchPlayerBySteamIdAndSeason(steamID, season);
      if (playerStats) {
        setPlayers(prevPlayers =>
          prevPlayers.map(playerWithSeason =>
            playerWithSeason.stats.steamID === steamID
              ? { ...playerWithSeason, stats: playerStats, selectedSeason: season }
              : playerWithSeason
          )
        );
      }
    } catch (err) {
      setError(err);
    }
  };

  const handleRemovePlayer = (steamID: string) => {
    setPlayers(prevPlayers => prevPlayers.filter(player => player.stats.steamID !== steamID));
  };

  return (
    <div className={`container ${isDark ? 'bg-dark text-light' : ''}`}>
      <div className="row">
        <div className="col-6">
          <div className="search-section">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Tilføj spiller til sammenligning"
                ref={inputElem}
                onChange={() => handleSearch(inputElem.current?.value)}
              />
            </div>

            {error && (
              <div className="alert alert-danger" role="alert">
                Kunne ikke forbinde til databasen
              </div>
            )}
            <div className="row">
              {searchResults.map((result, index) => (
                <div className="col-sm-6 col-md-3" key={index}>
                  <div className="mb-4">
                    <button
                      className="btn btn-primary text-truncate"
                      style={{ width: 100 }}
                      onClick={() => selectPlayer(result)}
                    >
                      {result.playerName}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            {players.map((playerWithSeason) => (
              <div className='col-sm-12 col-md-6' key={playerWithSeason.stats.steamID}>
                <div className={`card text-center m-1 ${isDark ? 'bg-dark text-light' : ''}`}>
                  <div className='card-body'>
                    <div className='text-end'>
                      <button
                        className='btn btn-danger btn-sm'
                        onClick={() => handleRemovePlayer(playerWithSeason.stats.steamID)}
                      >
                        X
                      </button>
                    </div>
                    <Link
                      to={`/player/${playerWithSeason.stats.steamID}`}
                      className={`text-decoration-none ${isDark ? 'text-light' : 'text-black'}`}
                    >
                      <PlayerImage id={playerWithSeason.stats.steamID} width={100} />
                      <h5 className='card-title'>
                        {playerWithSeason.stats.account
                          ? playerWithSeason.stats.account.playerName
                          : playerWithSeason.stats.steamID}
                      </h5>
                    </Link>
                    <select
                      value={playerWithSeason.selectedSeason}
                      onChange={(e) =>
                        handleSeasonChange(playerWithSeason.stats.steamID, parseInt(e.target.value))
                      }
                      className={`form-select form-select-sm mt-2 ${isDark ? 'bg-dark text-light border-secondary' : ''}`}
                    >
                      {playerSeasons[playerWithSeason.stats.steamID]?.map(season => (
                        <option key={season} value={season} className={isDark ? 'bg-dark text-light' : ''}>
                          Season {season}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <PlayerTable players={players.map(p => p.stats)} />
    </div>
  );
};

export default ComparePage;
