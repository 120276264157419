import { divisionMappings, mapMappings } from "../../Constants";
import { Match } from "../../models/Match";
import './../../styles/theme.css';
import { Link } from "react-router-dom";
import useLocalStorage from 'use-local-storage';

interface MatchDetailProps {
    matches: Match[];
}

const MatchDetail: React.FC<MatchDetailProps> = ({ matches }) => {
    const [isDark] = useLocalStorage<boolean>("isDark", false);

    let team1Wins = 0;
    let team2Wins = 0;
    const team1 = matches[0].team1;
    const team2 = matches[0].team2;

    if (matches.length > 1) {
        matches.forEach((match) => {
            if (match.team1score > match.team2score) {
                team1Wins++;
            } else {
                team2Wins++;
            }
        });
    } else {
        team1Wins = matches[0].team1score;
        team2Wins = matches[0].team2score;
    }

    return (
        <div className={`container ${isDark ? 'bg-dark text-light' : ''}`}>
            <div className="row text-center mb-3">
                <div className="col-12">
                    <div className="row align-items-center text-light" style={{ backgroundColor: '#f15c25', borderRadius: '10px' }}>
                        {/* Team 1 Section */}
                        <div className="col-4 d-flex align-items-center justify-content-center">
                            <Link
                                to={`/team/${team1.teamId}`}
                                className="text-decoration-none d-flex align-items-center text-light"
                            >
                                <img src={team1.teamLogo} alt="TeamLogo" style={{ width: 80, marginRight: '10px' }} />
                                <h4 className="m-0">{team1.teamName}</h4>
                            </Link>
                        </div>

                        {/* Match Score Section */}
                        <div className="col-4 text-center d-flex flex-column justify-content-center" style={{ backgroundColor: '#e25623', padding: '15px 0' }}>
                            <div className="text-uppercase small">
                                {divisionMappings.get(matches[0].division)} - Sæson {matches[0].season} -  {new Date(matches[0].match_time).toLocaleDateString('da-DK').replaceAll(".", "/")}
                            </div>
                            <h1 className="my-2">
                                <span className="text-white">{team1Wins}</span>
                                <span className="text-white"> : </span>
                                <span className="text-white">{team2Wins}</span>
                            </h1>
                            {
                                matches.length === 1 && (
                                    <div className="small text-white">
                                        {mapMappings.get(matches[0].mapName)}
                                    </div>
                                )
                            }
                        </div>

                        {/* Team 2 Section */}
                        <div className="col-4 d-flex align-items-center justify-content-center">
                            <Link
                                to={`/team/${team2.teamId}`}
                                className="text-decoration-none d-flex align-items-center text-light"
                            >
                                <h4 className="m-0 me-2">{team2.teamName}</h4>
                                <img src={team2.teamLogo} alt="TeamLogo" style={{ width: 80, marginRight: '10px' }} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MatchDetail;
