const EmptySlot = () => {
    return (
        <div className="card" style={{ minHeight: '290px', maxWidth: '200px',maxHeight: '290px' }}>
            <div className="card-body d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <p>Der er ingen spillere på denne plads</p>
                </div>
            </div>
        </div>
    );
}

export default EmptySlot;
