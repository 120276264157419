import React from 'react';

interface PostCardSectionProps {
    title: string;
    content: string;
    isDark: boolean; // Add isDark prop
}

const PostCardSection: React.FC<PostCardSectionProps> = ({ title, content, isDark }) => {
    const cardStyle = isDark
        ? { backgroundColor: '#2c2f33', color: '#ffffff' } // Replace with your desired color
        : {};

    return (
        <div 
            className="card mb-3"
            style={{ 
                ...cardStyle,
                width: "550px", // Fixed width
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}
        >
            <div className="card-body" style={{ overflow: "hidden" }}>
                <h6 className="card-subtitle mb-2" style={{ color: isDark ? '#ffffff' : '#6c757d' }}>
                    {title}
                </h6>
                <p className="card-text">{content}</p>
            </div>
        </div>
    );
};

export default PostCardSection;
