import { useState, useEffect } from 'react';
import { PlayerStats } from '../models/PlayerStats';
import { fetchPlayerBySteamIdAndSeason } from '../services/PlayerStatService';

const usePlayerStats = (steamId: string | undefined, season: number | null) => {
    const [stats, setStats] = useState<PlayerStats | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            if (!steamId || !season) {
                setLoading(false);
                return;
            }
            try {
                const data = await fetchPlayerBySteamIdAndSeason(steamId, season);
                if (data) {
                    setStats(data);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [steamId, season]);

    return { stats, loading };
};

export default usePlayerStats;
