import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getVetoStatsForTeamBySeason } from '../../services/MatchService';
import Loading from '../../components/Loading';
import StatsTable from './StatsTable/StatsTable';
import { MapStats } from '../../models/MapStats'; // Adjust the path as necessary

const TeamMapStats = ({ season }: { season: number }) => {
    const { teamId } = useParams<{ teamId: string }>();
    const [mapStats, setMapStats] = useState<MapStats[]>([]); // Set the correct type here
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!teamId) return;

        const fetchMapStats = async () => {
            setLoading(true);
            try {
                const stats = await getVetoStatsForTeamBySeason(teamId, season.toString());
                setMapStats(stats);
            } catch (err) {
                console.error(err);
            }
            setLoading(false);
        };

        fetchMapStats();
    }, [teamId, season]);

    return (
        <div className="container">
            {loading ? (
                <Loading />
            ) : (
                <StatsTable mapStats={mapStats} />
            )}
        </div>
    );
};

export default TeamMapStats;