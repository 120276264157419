import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import MatchTable from "../../components/MatchTabel";
import { getLatestSeason, getMatchesForTeamBySeason, getUpcomingMatchesForTeam } from "../../services/MatchService";
import { Match } from "../../models/Match";
import Loading from "../../components/Loading";
import MatchTabs from "../../components/MatchTabs";
import { UpcomingMatch } from "../../models/UpcomingMatch";
import UpcomingMatchTable from "../../components/UpcomingMatchTable";

const TeamMatches = ({ season }: { season: number }) => {
    const { teamId } = useParams<{ teamId: string }>();
    const [activeTab, setActiveTab] = useState<string>('results');
    const [results, setResults] = useState<Match[]>([]);
    const [currentSeason, setCurrentSeason] = useState<number>(season);
    const [loading, setLoading] = useState(true);
    const [matchesByDate, setMatchesByDate] = useState<{ [date: string]: UpcomingMatch[] }>({});

    const fetchUpcomingMatches = useCallback(async () => {
        if (!teamId) return;
        try {
            const newMatches = await getUpcomingMatchesForTeam(teamId);
            if (!newMatches || newMatches.length === 0) {
                return;
            }

            setMatchesByDate(prevMatchesByDate => {
                const updatedMatchesByDate = { ...prevMatchesByDate };
                newMatches.forEach(match => {
                    const matchDate = match.match_time.split('T')[0]; // Extract date part
                    updatedMatchesByDate[matchDate] = updatedMatchesByDate[matchDate] || [];
                    const existingMatchIndex = updatedMatchesByDate[matchDate].findIndex(m => m.challonge_id === match.challonge_id);
                    if (existingMatchIndex === -1) {
                        updatedMatchesByDate[matchDate].push(match);
                    } else {
                        // If the match already exists, update it
                        updatedMatchesByDate[matchDate][existingMatchIndex] = match;
                    }
                });
                return updatedMatchesByDate;
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [teamId]);



    const getNewestSeason = useCallback(async () => {
        try {
            const season = await getLatestSeason();
            setCurrentSeason(season);
        } catch (err) {
            console.error(err);
        }
    }, []);

    const getMatches = useCallback(async () => {
        try {
            if (!teamId) throw new Error('No teamId provided');
            const matches = await getMatchesForTeamBySeason(teamId, season);
            setResults(matches);
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    }, [teamId, season]);

    useEffect(() => {
        fetchUpcomingMatches();
    }, [fetchUpcomingMatches]);

    useEffect(() => {
        getMatches();
    }, [getMatches]);

    useEffect(() => {
        getNewestSeason();
    }, [getNewestSeason]);

    return (
        <div>
            {
                currentSeason === season ? (
                    <>
                        <MatchTabs onTabChange={setActiveTab} />
                        <hr />
                        {
                            loading ? (
                                <Loading />
                            ) : null
                        }

                        {
                            activeTab === 'upcoming' && (
                                <div>
                                    {
                                        Object.entries(matchesByDate).map(([date, matchesForDate], index) => (
                                            <div className="text-center" key={index}>
                                                <h2>{date}</h2>
                                                <UpcomingMatchTable matchesForDate={matchesForDate} />
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            activeTab === 'results' && (
                                <div>
                                    <MatchTable matches={results} compact={false} />
                                </div>
                            )
                        }
                    </>
                ) : (
                    <div>
                        <MatchTable matches={results} compact={false} />
                    </div>
                )
            }

        </div>
    );
};

export default TeamMatches;