export enum FantasyRole {
    IGL = "IGL",
    MainAWP = "Main AWP",
    HSMachine = "HS Machine",
    EntryFragger = "Entry Fragger",
    StatHunter = "Stathunter",
    Noob = "Noob",
    MrConsistent = "Mr. Consistent",
    PistolWarrior = "Pistol Warrior",
    MultiKiller = "MultiKiller",
}