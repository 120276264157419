import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchAllSeasonPlayerRankings } from '../../../services/PlayerStatService';
import Loading from '../../../components/Loading';
import { SeasonPlayerRanking, SeasonRankings } from '../../../types/types';
import medal from '../../../content/medal.png';
import './medals.css';

const PlayerAchievementsDisplay: React.FC = () => {
    const { steamId } = useParams<{ steamId?: string }>();
    const [seasons, setSeasons] = useState<number[]>([]);
    const [rankings, setRankings] = useState<SeasonRankings>({});
    const [loading, setLoading] = useState<boolean>(false);

    // Fetch and group rankings
    const fetchRankingsAndSeasons = useCallback(async () => {
        setLoading(true);
        if (!steamId) {
            console.error('Steam ID is undefined');
            setLoading(false);
            return;
        }
        try {
            const rankingsData = await fetchAllSeasonPlayerRankings(steamId);
            const groupedRankings = rankingsData.reduce((acc, ranking) => {
                if (!acc[ranking.season]) {
                    acc[ranking.season] = [];
                }
                acc[ranking.season].push(ranking);
                return acc;
            }, {} as SeasonRankings);
            setSeasons(Object.keys(groupedRankings).map(Number).sort((a, b) => b - a));
            setRankings(groupedRankings);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }, [steamId]);

    useEffect(() => {
        fetchRankingsAndSeasons();
    }, [fetchRankingsAndSeasons]);

    const getMedalClass = (rank: number): string => {
        if (rank <= 10) return 'gold';
        else if (rank <= 50) return 'red';
        else if (rank <= 100) return 'pink';
        else if (rank <= 200) return 'purple';
        else if (rank <= 350) return 'blue';
        else if (rank <= 500) return 'lightblue';
        else return 'white'; // default color for ranks beyond 600
    };

    const calculateTextColor = (bgColorClass: string): string => {
        return 'black';
    };

    const renderRow = (season: number) => {
        const seasonRankings = rankings[season];
        if (!seasonRankings) {
            return Array(6).fill(null).map((_, i) => <td key={i}>-</td>);
        }
        return seasonRankings.map((ranking) => (
            <td key={ranking.stat}>{renderSeasonMedals(ranking)}</td>
        ));
    }


    const renderSeasonMedals = (ranking: SeasonPlayerRanking) => {
        const bgColorClass = getMedalClass(ranking.rank);
        const innerCircleColor = {
            'gold': 'rgb(255, 215, 0)',
            'red': 'rgb(235, 75, 75)',
            'pink': 'rgb(211, 46, 230)',
            'purple': 'rgb(136, 71, 255)',
            'blue': 'rgb(75, 105, 255)',
            'lightblue': 'rgb(94, 152, 217)',
            'white': 'rgb(176, 195, 217)', // or 'white' if that was intended
            'grey': 'grey',
        }[bgColorClass];

        // Dynamically calculate text color based on background color
        const textColor = calculateTextColor(bgColorClass);

        return (
            <div className={`season-medals medal`}>
                <svg width="110" height="110">
                    <image href={medal} width="100%" height="100%" />
                    <circle cx="50%" cy="66.8%" r="25" fill={innerCircleColor} /> { }
                    <text x="50%" y="70%" dominantBaseline="middle" textAnchor="middle" fill={textColor} fontSize="19">{ranking.rank}</text>
                </svg>
            </div>
        );
    };


    if (loading) {
        return <Loading />;
    }

    if (!rankings || seasons.length === 0) {
        return <div className="container text-center"><h1>Ingen ranks fundet</h1></div>;
    }

    return (
        <div className="container text-center d-flex align-items-start justify-content-center">
            <div className='table-responsive '>
                <thead>
                    <tr>
                        <th className="table-header">Sæson</th>
                        <th className="table-header">KD</th>
                        <th className="table-header">Rating 2.0</th>
                        <th className="table-header">ADR</th>
                        <th className="table-header">HS</th>
                        <th className="table-header">KAST</th>
                        <th className="table-header">OPW%</th>
                    </tr>
                </thead>
                <tbody>
                    {seasons.map((season) => (
                        <tr key={season}>
                            <th className="season-header">{season} </th>
                            {renderRow(season)}
                        </tr>
                    ))}
                </tbody>
            </div>
        </div>
    );
};

export default PlayerAchievementsDisplay;