import React from "react";
import { Link } from "react-router-dom";
import { PlayerMatch } from "../../models/PlayerMatch";
import { Team } from "../../models/Team";
import useLocalStorage from 'use-local-storage';

interface ScoreboardProps {
    team: Team;
    players: PlayerMatch[];
    teamScore: number | undefined; // Add teamScore prop
}

const Scoreboard: React.FC<ScoreboardProps> = ({ team, players, teamScore }) => {
    const [isDark] = useLocalStorage<boolean>("isDark", false);
    players.sort((a, b) => b.rating2 - a.rating2);

    return (
        <>
            <Link to={`/team/${team.teamId}`} className="text-decoration-none team-name">
                <div className="d-flex align-items-center">
                    <img src={team.teamLogo} alt="TeamLogo" style={{ width: 40 }} />
                    <h4 className="ms-2">
                        {team.teamName}
                        {
                            teamScore !== undefined ? (
                                ` (${teamScore})`
                            ) : null
                        }
                    </h4>
                </div>
            </Link>
            <table
                className={`table table-striped ${isDark ? 'table-dark' : ''}`}
                style={
                    isDark
                        ? {
                            border: '1px solid #111',
                            borderCollapse: 'collapse' as const,
                        }
                        : undefined
                }
            >
                <thead>
                    <tr>
                        <th>Navn</th>
                        <th>K/D/A</th>
                        <th>+/-</th>
                        <th>ADR</th>
                        <th>KAST%</th>
                        <th>Rating 2.0</th>
                    </tr>
                </thead>
                <tbody>
                    {players.map((player, index) => (
                        <tr key={index}>
                            <td>
                                <Link to={`/player/${player.steamId}`} className={`text-decoration-none ${isDark ? 'text-light' : 'text-dark'}`}>
                                    {player.account?.playerName || player.steamId}
                                </Link>
                            </td>
                            <td>{player.kills}/{player.deaths}/{player.assists}</td>
                            <td>{player.kills - player.deaths}</td>
                            <td>{player.adr}</td>
                            <td>{player.kast}%</td>
                            <td>{player.rating2}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default Scoreboard;
