import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import '../styles/theme.css';
import RecentMatches from "../components/RecentMatches";
import { getNextParsing } from "../services/TimerService";

const HomePage = () => {
    const [nextUpdate, setNextUpdate] = useState<Date | null>(null); // Change the type to Date
    const [countdown, setCountdown] = useState<string>("");

    useEffect(() => {
        const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const fetchNextParsing = async () => {
            try {
                const nextTimeUtc = await getNextParsing(timeZoneId);
                if (nextTimeUtc) {
                    // Convert UTC time to local time
                    const nextLocalTime = new Date(nextTimeUtc);
                    setNextUpdate(nextLocalTime);
                }
            } catch (error) {
                console.error("Failed to fetch the next parsing time:", error);
            }
        };

        fetchNextParsing();
    }, []);

    useEffect(() => {
        if (nextUpdate) {
            const interval = setInterval(() => {
                const now = new Date();
                const timeDifference = nextUpdate.getTime() - now.getTime();
    
                if (timeDifference <= 0) {
                    clearInterval(interval);
                    setCountdown("Updating now...");
                } else {
                    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
                    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
                    const seconds = Math.floor((timeDifference / 1000) % 60);
                    setCountdown(
                        `${days > 0 ? days + " days, " : ""}${hours} hours, ${minutes} minutes, ${seconds} seconds`
                    );
                }
            }, 1000);
    
            return () => clearInterval(interval);
        }
    }, [nextUpdate]);
    

    return (
        <div className="text-center">
            <h1>Velkommen til PowerStats</h1>
            <p>Her kan du se statistikker for alle spillere og hold i PowerLigaen fra sæson 18-27</p>

            <div className="alert alert-warning text-center my-4" role="alert" style={{ fontSize: "1.5rem", fontWeight: "bold", padding: "20px" }}>
            🌟 <strong>Rising Stars LAN</strong> 🌟 <br />
            Finder sted den <strong>29., 30. november</strong> og <strong>1. december</strong>! <br />
            Hold dig opdateret på 
            <a className="text-decoration-none mx-1" href="https://rising.powerstats.dk/" target="_blank" rel="noreferrer">
                <strong>rising.powerstats.dk</strong>
            </a>
        </div>

            <div>
                <a className="text-decoration-none" href="https://qr.mobilepay.dk/box/eb8fedbd-3c2c-4db7-b65f-5e5f1be50c23/pay-in" target="_blank" rel="noreferrer">
                    <button className="btn btn-primary btn-lg m-3">Støt os på MobilePay</button>
                </a>
                <a className="text-decoration-none" href="https://discord.gg/W54Zhj2PD3" target="_blank" rel="noreferrer">
                    <button className="btn btn-primary btn-lg m-3">Join vores Discord</button>
                </a>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-4 col-sm-6">
                    <Link className="btn btn-primary btn-lg m-3 d-block" to="/leaderboard">Leaderboard</Link>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Link className="btn btn-primary btn-lg m-3 d-block" to="/lft">Free Agents</Link>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Link className="btn btn-primary btn-lg m-3 d-block" to="/compare">Sammenlign</Link>
                </div>
            </div>
            <hr />
            <div className="text-center">
                <h1>Seneste resultater</h1>
                <p>Her kan du se de seneste resultater</p>
                <p>Næste opdatering om: {countdown}</p> {/* Display the countdown */}
            </div>
            <hr />
            <RecentMatches compact={false} />
        </div>
    );
};

export default HomePage;
