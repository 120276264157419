import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { createFantasyTeam, deleteFantasyTeam, getAllTeams, getFantasyTeams, getFantasyPlayerMatchesByTeamId, getFantasyLeaderboard, isCurrentSeasonLocked } from "../../services/FantasyService";
import { AuthContext } from "../../context/AuthContext";
import { currentSeason, divisionMappings } from "../../Constants";
import { getDivisionsBySeason } from "../../services/MatchService";
import { Division } from "../../types/types";
import { FantasyTeam } from "../../models/FantasyTeam";
import { FantasyPlayer } from "../../models/FantasyPlayer";
import { FantasyPlayerMatch } from "../../models/FantasyPlayerMatch"; // Import added
import { FantasyStartTeam } from "../../models/FantasyStartTeam";
import TeamSlot from "./TeamSlot";
import EmptySlot from "./EmptySlot";
import PlayerSlot from "./PlayerSlot";
import Loading from "../../components/Loading";
import { Delete } from "@mui/icons-material";
import { FantasyRole } from "../../models/FantasyRole";
import { range } from "lodash";
import { Link } from "react-router-dom";

function formatDateToDanish(dateInput: string | Date): string {
    let date: Date;

    if (dateInput instanceof Date) {
        date = dateInput;
    } else {
        date = new Date(dateInput);
    }

    const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
    };

    return date.toLocaleDateString('da-DK', options).replace("kl.", "").trim();
}

const CreateFantasyPage = () => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [selectedDivision, setSelectedDivision] = useState<Division | null>(null);
    const [currentGroup, setCurrentGroup] = useState<string | null>(null);
    const [team, setTeam] = useState<FantasyTeam | null>(null);
    const [selectedPlayers, setSelectedPlayers] = useState<FantasyPlayer[]>([]);
    const [fantasyTeams, setFantasyTeams] = useState<FantasyTeam[]>([]);
    const [allTeams, setAllTeams] = useState<FantasyStartTeam[]>([]);
    const [money, setMoney] = useState<number>(1000000);
    const [ranking, setRanking] = useState<number | null>(null); // Add ranking state
    const allRoles = useMemo(() => Object.values(FantasyRole), []);
    const [availableRoles, setAvailableRoles] = useState<string[]>(Object.values(FantasyRole));
    const [isLocked, setIsLocked] = useState<boolean>(false);


    // New states for matches and showing match details
    const [matches, setMatches] = useState<FantasyPlayerMatch[]>([]);
    const [showMatches, setShowMatches] = useState(false);

    const { user: visitor, token } = useContext(AuthContext);

    const onPlayerRemoveClick = useCallback((player: FantasyPlayer) => {
        const newPlayers = [...selectedPlayers];
        newPlayers.splice(newPlayers.indexOf(player), 1);
        setSelectedPlayers(newPlayers);
    }, [selectedPlayers]);

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('da-DK', {
            style: 'currency',
            currency: 'DKK',
            minimumFractionDigits: 0,
        }).format(amount);
    };

    const fetchIsLocked = useCallback(async () => {
        try {
            const response = await isCurrentSeasonLocked();
            setIsLocked(response);
        } catch (err) {
            setError(err instanceof Error ? err.message : String(err));
        }
    }, []);

    useEffect(() => {
        fetchIsLocked();
    }, [fetchIsLocked]);

    const updateAvailableRoles = useCallback(() => {
        const selectedRoles = selectedPlayers.map(p => p.role);
        const availableRoles = allRoles.filter(role => !selectedRoles.includes(role));
        setAvailableRoles(availableRoles);
    }, [selectedPlayers, allRoles]);

    // Add useEffect to watch for changes in selectedPlayers
    useEffect(() => {
        updateAvailableRoles();
    }, [selectedPlayers, updateAvailableRoles]);

    const onPlayerRoleClick = useCallback((player: FantasyPlayer, newRole: string) => {
        const roleObject = allRoles.find((r) => r === newRole) as FantasyRole;

        setSelectedPlayers((prevSelectedPlayers) =>
            prevSelectedPlayers.map((p) =>
                p.account.steamID === player.account.steamID
                    ? new FantasyPlayer(
                        p.stats,
                        p.account,
                        p.price,
                        roleObject,
                        p.playerPoints,
                        p.rolePoints,
                        p.teamPoints,
                        p.rarity,
                        p.teamId
                    )
                    : p
            )
        );
    }, [allRoles]);


    const onPlayerAddClick = useCallback((player: FantasyPlayer) => {
        if (!player || selectedPlayers.length === 5) {
            return;
        }

        // Use incomingTeamId for team comparison
        const sameTeamPlayers = selectedPlayers.filter(
            selectedPlayer => selectedPlayer.incomingTeamId === player.incomingTeamId
        );

        if (sameTeamPlayers.length >= 2) {
            alert(`Du kan ikke vælge mere end 2 spillere fra samme hold.`);
            return;
        }

        const newPlayers = [...selectedPlayers];
        newPlayers.push(player);
        setSelectedPlayers(newPlayers);

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [selectedPlayers]);


    const fetchTeamRanking = useCallback(async (team: FantasyTeam) => {
        if (!team || !team.division) return;

        try {
            const leaderboard = await getFantasyLeaderboard(team.season, visitor == null ? null : visitor.steamID);
            const divisionTeams = leaderboard[team.division];
            if (divisionTeams) {
                const teamRank = divisionTeams.findIndex(t => t.account.steamID === team.account.steamID);
                setRanking(teamRank !== -1 ? teamRank + 1 : null); // Ranking starts from 1
            }
        } catch (err) {
            console.error("Error fetching team ranking:", err);
        }
    }, [visitor]);

    const fetchFantasyTeams = useCallback(async () => {
        if (!visitor) {
            return;
        }

        //if player dosnt exist create him with blank data at 200k
        try {
            const response = await getFantasyTeams(visitor.steamID);
            // Filter teams to include only those from the current season,

            //we shuld look at last played season if they dont have stats for current season.
            const currentSeasonTeams = response.filter(team => team.season === currentSeason);

            if (currentSeasonTeams.length > 0) {
                const firstTeam = currentSeasonTeams[0];
                setTeam(firstTeam);
                setSelectedPlayers(firstTeam.players);
                setSelectedDivision(divisions.find(div => div.division === firstTeam.division) || null);

                if (isLocked) {
                    await fetchTeamRanking(firstTeam); // Fetch ranking if team is locked
                }
            }
            setFantasyTeams(currentSeasonTeams);
        } catch (err) {
            setError(err instanceof Error ? err.message : String(err));
        }
    }, [visitor, divisions, isLocked, fetchTeamRanking]);


    const resetEverything = useCallback(() => {
        setCurrentGroup(null);
        setSelectedPlayers([]); // Clear selected players
        setAllTeams([]);
        setMoney(1000000);
        setTeam(null); // Reset the team as well
    }, []);

    const isValidTeam = () => {
        return selectedPlayers.length === 5 && money >= 0;
    }

    const createNewFantasyTeam = useCallback(async () => {
        if (!visitor || !token) {
            return;
        }
        // check if all players have a role
        if (selectedPlayers.some(player => player.role === null)) {
            alert("Du skal vælge en rolle for alle spillere.");
            return;
        }

        const fantasyTeam: FantasyTeam = {
            season: currentSeason,
            account: visitor,
            id: team?.id ?? 0,
            locked: false,
            division: selectedDivision!.division,
            players: selectedPlayers,
            points: 0,
        }
        setLoading(true);
        try {
            const success = await createFantasyTeam(token, fantasyTeam);
            if (success) {
                resetEverything();
                setSelectedDivision(null);
                alert("Fantasy hold oprettet!");
                window.location.reload();
            } else {
                alert("Kunne ikke oprette fantasy hold");
            }
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : String(err);
            console.error(errorMessage);
            alert("Der skete en fejl");
        } finally {
            setLoading(false);
        }
    }, [visitor, token, team?.id, selectedDivision, selectedPlayers, resetEverything]);

    const fetchAllTeams = useCallback(async () => {
        if (!currentSeason || !selectedDivision) {
            return;
        }
        if (selectedDivision.group && !currentGroup) {
            return;
        }

        setLoading(true);
        try {
            const response = await getAllTeams(currentSeason, selectedDivision.division, currentGroup);
            setAllTeams(response);
        } catch (err) {
            setError(err instanceof Error ? err.message : String(err));
        } finally {
            setLoading(false);
        }
    }, [selectedDivision, currentGroup]);

    const loadDivisions = useCallback(async () => {
        try {
            const divisions = await getDivisionsBySeason(currentSeason - 1);
            const divisionMap = new Map();
            divisions.forEach((division) => {
                const formattedDivision = divisionMappings.get(division) || division;
                if (division === 'ligaen') {
                    divisionMap.set(division, createDivision(division, formattedDivision, null));
                }

                const divisionAndGroup = division.match(/^(\d+div)([A-Z])?$/);
                if (divisionAndGroup) {
                    const [divisionPrefix, group] = divisionAndGroup.slice(1);
                    if (divisionMap.has(divisionPrefix)) {
                        const currentDivision = divisionMap.get(divisionPrefix);
                        if (currentDivision?.group) {
                            currentDivision.group.push(group);
                        } else {
                            currentDivision.group = [group];
                        }
                        divisionMap.set(divisionPrefix, currentDivision);
                    } else {
                        divisionMap.set(divisionPrefix, createDivision(divisionPrefix, formattedDivision, group));
                    }
                }
            });
            const divisionArray = Array.from(divisionMap.values());
            divisionArray.sort((a, b) => {
                if (a.division === 'ligaen') {
                    return -1;
                }
                if (b.division === 'ligaen') {
                    return 1;
                }
                return a.division.localeCompare(b.division);
            });
            setDivisions(divisionArray);
        } catch (err) {
            setError(err instanceof Error ? err.message : String(err));
        }
    }, []);

    const createDivision = (divisionPrefix: string, divisionName: string, group: string | null) => {
        if (divisionName === 'Ligaen') {
            return {
                division: divisionPrefix,
                formattedDivision: divisionName,
                group: null,
            };
        }
        const splittedDiv = divisionName.split(" ", 2);
        const div = `${splittedDiv[0]} ${splittedDiv[1]}`;
        return {
            division: divisionPrefix,
            formattedDivision: div,
            group: group ? [group] : null,
        };
    };

    useEffect(() => {
        fetchAllTeams();
    }, [fetchAllTeams]);

    useEffect(() => {
        fetchFantasyTeams();
    }, [fetchFantasyTeams]);

    useEffect(() => {
        loadDivisions();
    }, [loadDivisions]);

    useEffect(() => {
        const calculateMoney = () => {
            const totalSpent = selectedPlayers.reduce((acc, player) => acc + player.price, 0);
            setMoney(1000000 - totalSpent);
        };

        calculateMoney();
    }, [selectedPlayers]);

    useEffect(() => {
        if (currentSeason && selectedDivision) {
            // Only fetch the team if it's in the same division and not locked
            const team = fantasyTeams.find(team => team.season === currentSeason && team.division === selectedDivision.division);
            if (team && !isLocked) {
                setTeam(team);
                setSelectedPlayers(team.players);
            } else {
                setTeam(null); // Ensure no team is selected if not found in the division
            }
        }
    }, [selectedDivision, fantasyTeams, isLocked]);


    const handleViewMatches = useCallback(async () => {
        if (team) {
            try {
                let fetchedMatches = await getFantasyPlayerMatchesByTeamId(team.id);
                fetchedMatches = fetchedMatches.sort((a, b) => new Date(b.matchTime).getTime() - new Date(a.matchTime).getTime());
                setMatches(fetchedMatches);
                setShowMatches(!showMatches);
            } catch (err) {
                setError("Failed to fetch matches.");
            }
        }
    }, [team, showMatches]); // Ensure showMatches is in the dependency array

    if (!visitor) {
        return (
            <div>
                Du er ikke logget ind, log venligst ind.
            </div>
        )
    }

    if (token === null) {
        return (
            <div>
                Du er ikke logget ind, log venligst ind.
            </div>
        )
    }

    if (error) {
        return (
            <div>
                <div className="container text-center">
                    <h1>Opret Fantasy hold</h1>
                    <p>Her kan du oprette et fantasy hold for hver division. Vælg en spiller ved at trykke på spilleren</p>
                    <hr />
                    <p>Der skete en fejl</p>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="container text-center">
                <h1>Opret Fantasy hold</h1>
                <p>Her kan du oprette et fantasy hold for hver division. Vælg en spiller ved at trykke på spilleren</p>
                <div className="d-flex justify-content-center p-1">
                    <Link className="btn btn-primary" to="https://docs.google.com/document/d/1J8Wi6D1bBzSX8quMnpKpjZG1m2wvY4PUtiRmrBMo13s" target="_blank">
                        Se guide og rolle info
                    </Link>
                </div>
                {
                    !isLocked && (
                        <>
                            <hr />
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-4">
                                    Vælg division
                                    <div className="dropdown mb-2">
                                        <button className="btn btn-primary dropdown-toggle w-100" type="button" id="seasonDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                            {selectedDivision ? selectedDivision.formattedDivision : "Alle"}
                                        </button>
                                        <ul className="dropdown-menu w-100" aria-labelledby="divisionDropdown">
                                            {divisions.map((division) => (
                                                <li key={division.division}>
                                                    <button
                                                        className="dropdown-item"
                                                        onClick={() => {
                                                            setSelectedDivision(division);
                                                            resetEverything();  // Already resets players and other details
                                                            setTeam(null);      // Reset team so it doesn't default to the existing one
                                                        }}
                                                    >
                                                        {division.formattedDivision}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }

                {ranking !== null && (
                    <p><strong>Current Ranking:</strong> {ranking}</p>
                )}
            </div>
            <hr />
            <div className="d-flex justify-content-between p-2">
                <div>
                    {
                        team?.locked ? (
                            <h4 className="text-center">Full Team Points: {team.points}</h4>
                        ) : (
                            <>
                                <h4 className="text-center">Saldo: {formatCurrency(money)}</h4>
                                {money < 0 && <p className="text-center">Du har for lav saldo. Du kan ikke lave hold med negative saldo.</p>}
                            </>
                        )
                    }
                </div>
                <div>
                    {
                        team !== null && !isLocked && (
                            <button className="btn btn-danger fantasy-btn mx-2" onClick={() => deleteFantasyTeam(token, team)}>
                                <Delete />
                            </button>
                        )
                    }
                    {!isLocked ? (
                        <button className="btn btn-primary" onClick={createNewFantasyTeam} disabled={!isValidTeam()}>
                            Opret/Opdater Fantasy Team
                        </button>
                    ) : (
                        <button className="btn btn-secondary" disabled>
                            Team Locked
                        </button>
                    )}
                </div>
            </div>


            <div className="row justify-content-center text-center">
                {
                    selectedPlayers.map((player) => (
                        <div className="col-12 col-md-2" key={player.account.steamID}>
                            <PlayerSlot
                                player={player}
                                isSelected={true}
                                onPlayerRemoveClick={onPlayerRemoveClick}
                                locked={team?.locked}
                            />
                            {
                                team?.locked ? (
                                    <div className="mt-2 p-2 text-center" style={{ border: '1px solid #ccc', borderRadius: '5px' }}>
                                        <strong>Current Points: </strong>
                                        <span>{player.totalPoints}</span>
                                    </div>
                                ) : (
                                    <div className="dropdown mt-2">
                                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                            {player.role ? player.role : "Vælg en rolle"}
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            {availableRoles.map((role) => (
                                                <li key={role} onClick={() => onPlayerRoleClick(player, role)}>
                                                    <button className="dropdown-item">
                                                        {role}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )
                            }
                        </div>
                    ))
                }

                {
                    range(5 - selectedPlayers.length).map((_, index) => (
                        <div className="col-12 col-md-2" key={index}>
                            <EmptySlot />
                        </div>
                    ))
                }
            </div>
            {team?.locked && (
                <>
                    <hr />
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <h4>Detailed Breakdown</h4>
                            <hr />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        {selectedPlayers.map((player) => (
                            <div className="col-12 col-md-2 mb-4" key={player.account.steamID}>
                                <div className="p-2 text-center" style={{ backgroundColor: '#333', color: '#fff', borderRadius: '5px', border: '1px solid #ccc' }}>
                                    <p style={{ margin: 0 }}><strong>Player Points:</strong> {player.playerPoints}</p>
                                    <p style={{ margin: 0 }}><strong>Role Points:</strong> {player.rolePoints}</p>
                                    <p style={{ margin: 0 }}><strong>Team Points:</strong> {player.teamPoints}</p>
                                    <p style={{ margin: 0, fontWeight: 'bold' }}><strong>Total Points:</strong> {player.playerPoints + player.rolePoints + player.teamPoints}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="col-12 text-center">
                            <button className="btn btn-primary" onClick={handleViewMatches}>
                                {showMatches ? "Hide Detailed Games" : "View Detailed Games"}
                            </button>
                        </div>
                    </div>
                    {showMatches && matches.length > 0 && (
                        <div className="row justify-content-center mt-4">
                            <div className="col-12 text-center">
                                <h4>Match Details</h4>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Player Name</th>
                                            <th>Player Points</th>
                                            <th>Role Points</th>
                                            <th>Team Points</th>
                                            <th>Total Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {matches.map((match: FantasyPlayerMatch) => (
                                            <tr key={match.matchId} style={{ cursor: 'pointer' }}>
                                                <td>{formatDateToDanish(match.matchTime)}</td>
                                                <td>{team.players.find(p => p.account.steamID === match.steamId)?.account.playerName}</td>
                                                <td>{match.playerPoints}</td>
                                                <td>{match.rolePoints}</td>
                                                <td>{match.teamPoints}</td>
                                                <td>{match.playerPoints + match.rolePoints + match.teamPoints}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </>
            )}
            <hr />
            {
                !team?.locked && divisions.find((division) => division.division === selectedDivision?.division)?.group && (
                    <div className="row justify-content-center text-center">
                        <div className="col-12 col-md-4">
                            Vælg gruppe
                            <div className="dropdown mb-2">
                                <button className="btn btn-primary dropdown-toggle w-100" type="button" id="seasonDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                    {currentGroup ? currentGroup[0] : 'Vælg gruppe'}
                                </button>
                                <ul className="dropdown-menu w-100" aria-labelledby="groupDropdown">
                                    {
                                        divisions.find((division) => division.division === selectedDivision?.division)?.group?.map((group) => (
                                            <li key={group}>
                                                <button
                                                    className="dropdown-item"
                                                    onClick={() => setCurrentGroup(group)}
                                                >
                                                    {group}
                                                </button>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                isLoading ? (
                    <Loading />
                ) : (
                    allTeams.map((team) => (
                        <TeamSlot
                            team={team}
                            onPlayerAddClick={(player: FantasyPlayer) => onPlayerAddClick(player)}
                        />
                    ))
                )
            }
        </div>
    )
}

export default CreateFantasyPage;
