import React, { useState } from 'react';
import { MapStats } from '../../../models/MapStats';
import useLocalStorage from 'use-local-storage'; // Import useLocalStorage
import './StatsTable.css'; // Ensure this CSS file has the necessary styles

interface StatsTableProps {
    mapStats: MapStats[];
}

const StatsTable: React.FC<StatsTableProps> = ({ mapStats }) => {
    const [sortConfig, setSortConfig] = useState<{ key: keyof MapStats; direction: 'asc' | 'desc' } | null>(null);
    const [isDark] = useLocalStorage<boolean>('isDark', false); // Retrieve isDark mode

    const sortData = (key: keyof MapStats) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        } else if (sortConfig && sortConfig.key === key && sortConfig.direction === 'desc') {
            direction = 'asc';
        }

        setSortConfig({ key, direction });
    };

    const sortedStats = React.useMemo(() => {
        let sortableItems = [...mapStats];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // Handle string and number comparisons
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    aValue = aValue.toLowerCase();
                    bValue = bValue.toLowerCase();
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [mapStats, sortConfig]);

    if (mapStats.length === 0) {
        return (
            <div>
                Der er ingen data at vise
            </div>
        );
    }

    return (
        <div className="container">
            <table
                className={`table table-striped ${isDark ? 'table-dark' : ''}`}
                style={
                    isDark
                        ? {
                            border: '1px solid #111',
                            borderCollapse: 'collapse' as 'collapse',
                        }
                        : undefined
                }
            >
                <thead>
                    <tr>
                        <th onClick={() => sortData('mapName')} style={{ cursor: 'pointer' }}>
                            Map {sortConfig?.key === 'mapName' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => sortData('amountPicked')} style={{ cursor: 'pointer' }}>
                            Amount Picked {sortConfig?.key === 'amountPicked' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => sortData('amountBanned')} style={{ cursor: 'pointer' }}>
                            Amount Banned {sortConfig?.key === 'amountBanned' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => sortData('amountPlayed')} style={{ cursor: 'pointer' }}>
                            Amount Played {sortConfig?.key === 'amountPlayed' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => sortData('amountWon')} style={{ cursor: 'pointer' }}>
                            Amount Won {sortConfig?.key === 'amountWon' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => sortData('chosenCTPercentage')} style={{ cursor: 'pointer' }}>
                            Chosen CT% {sortConfig?.key === 'chosenCTPercentage' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedStats.map(stat => (
                        <tr key={stat.mapName}>
                            <td>{stat.mapName}</td>
                            <td>{stat.amountPicked}</td>
                            <td>{stat.amountBanned}</td>
                            <td>{stat.amountPlayed}</td>
                            <td>{stat.amountWon}</td>
                            <td>{stat.chosenCTPercentage}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StatsTable;
