import { useEffect, useState, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { getFantasySimpleTeams } from "../../services/FantasyService";
import Loading from "../../components/Loading";
import { FantasyTeam } from "../../models/FantasyTeam";

const ViewAllTeamsPage = () => {
    const { steamID, season } = useParams<{ steamID: string; season: string }>();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');
    const [teams, setTeams] = useState<FantasyTeam[]>([]);

    const fetchTeams = useCallback(async () => {
        if (!steamID || !season) {
            setError("Invalid parameters.");
            setLoading(false);
            return;
        }

        try {
            const teams = await getFantasySimpleTeams(steamID, Number(season));
            const teamsForSeason = teams.filter((team: FantasyTeam) => team.season === Number(season));
            setTeams(teamsForSeason);
        } catch (err) {
            setError(err instanceof Error ? err.message : "An unexpected error occurred");
        } finally {
            setLoading(false);
        }
    }, [steamID, season]);

    useEffect(() => {
        fetchTeams();
    }, [fetchTeams]);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <div className="text-center"><p>{error}</p></div>;
    }

    if (!teams.length) {
        return <div className="text-center"><p>Ingen hold fundet for denne sæson.</p></div>;
    }

    return (
        <div className="container text-center">
            <h1>{teams[0].account.playerName}s Hold for Sæson {season}</h1>
            <div className="row">
                {teams.map((team) => (
                    <div className="col-12 col-md-4 mb-4" key={team.id}>
                        <div className="card p-3">
                            <h5>{team.account.playerName}'s Hold i {team.division}</h5>
                            <p>Points: {team.points}</p>
                            <Link to={`/fantasy/view/${steamID}/${season}/${team.division}`} className="btn btn-primary">
                                Se Hold
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ViewAllTeamsPage;
