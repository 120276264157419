import { useCallback, useEffect, useState } from "react";
import { divisionMappings, roles } from "../../Constants";
import { LFT } from "../../models/LFT";
import RangeSlider from "../../components/react-range-slider-input/dist";
import '../../components/react-range-slider-input/dist/style.css';

interface FiltersProps {
    players: LFT[];
    setPlayers: (players: LFT[]) => void;
}

const Filters: React.FC<FiltersProps> = ({ players, setPlayers }) => {
    const [ageRangeLimits, setAgeRangeLimits] = useState<[number, number]>([0, 100]);
    const [ageRange, setAgeRange] = useState<[number, number]>([0, 100]);
    const [selectedDivisions, setSelectedDivisions] = useState<string[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

    useEffect(() => {
        // get player with lowest age
        const minAge = Math.min(...players.map(player => player.account.age));
        // get player with highest age
        const maxAge = Math.max(...players.map(player => player.account.age));
        setAgeRangeLimits([minAge, maxAge]);
        setAgeRange([minAge, maxAge]);
    }, [players]);

    const sortByDivision = useCallback((players: LFT[]) => {
        players.sort((a, b) => {
            // if both players are without a team, sort by rating
            if (!a.team && !b.team) {
                // if both players have no stats, sort by elo
                if (!a.stats && !b.stats) {
                    return b.account.faceitElo - a.account.faceitElo;
                }
                // if one player has no stats, sort the other player first
                if (!a.stats) {
                    return 1;
                }
                if (!b.stats) {
                    return -1;
                }
            }
            // if one player is without a team, sort the other player first
            if (!a.team) {
                return 1;
            }
            if (!b.team) {
                return -1;
            }
            // if both players have a team, sort by division
            return divisionMappings.get(a.team.division)!.localeCompare(divisionMappings.get(b.team.division)!);
        });
        return players;
    }, []);

    const sortPremiumFirst = useCallback((players: LFT[]) => {
        players.sort((a, b) => {
            if (a.account.premium && !b.account.premium) {
                return -1;
            }
            if (!a.account.premium && b.account.premium) {
                return 1;
            }
            return 0;
        });
        return players;
    }, []);

    const applyFilters = useCallback(() => {
        let filteredPlayers = players.filter(player => {
            return player.account.age >= ageRange[0] && player.account.age <= ageRange[1] &&
                (selectedDivisions.length === 0 || selectedDivisions.includes(divisionMappings.get(player.team?.division!)?.split(" ").slice(0, 2).join(" ") || '')) &&
                (selectedRoles.length === 0 || selectedRoles.includes(player.account.role));
        });
        filteredPlayers = sortByDivision(filteredPlayers);
        filteredPlayers = sortPremiumFirst(filteredPlayers);
        setPlayers(filteredPlayers);
    }, [ageRange, players, selectedDivisions, selectedRoles, setPlayers, sortByDivision, sortPremiumFirst]);

    const handleDivisionCheckboxChange = useCallback((division: string) => {
        if (selectedDivisions.includes(division)) {
            setSelectedDivisions(selectedDivisions.filter(d => d !== division));
        } else {
            setSelectedDivisions([...selectedDivisions, division]);
        }
    }, [selectedDivisions]);

    const handleRoleCheckboxChange = useCallback((role: string) => {
        if (selectedRoles.includes(role)) {
            setSelectedRoles(selectedRoles.filter(d => d !== role));
        } else {
            setSelectedRoles([...selectedRoles, role]);
        }
    }, [selectedRoles]);

    const getDivisions = useCallback((divisions: Map<string, string>): string[] => {
        let divisionSet = new Set<string>();
        divisions.forEach((value) => {
            divisionSet.add(value.split(" ").slice(0, 2).join(" "));
        });
        return Array.from(divisionSet);
    }, []);

    useEffect(() => {
        applyFilters();
    }, [ageRange, applyFilters]);

    return (
        <div>
            <h5>Filtre</h5>
            <div className="mb-3">
                <h6>Alder</h6>
                <div className="text-center mb-2">
                    {
                        ageRange[0] === ageRangeLimits[0] && ageRange[1] === ageRangeLimits[1] ? "Alle" : `${ageRange[0]} år - ${ageRange[1]} år`
                    }
                </div>
                <RangeSlider value={ageRange} onInput={setAgeRange} min={ageRangeLimits[0]} max={ageRangeLimits[1]} rangeSlideDisabled={true} />
            </div>
            <div className="mb-3">
                <h6>Division</h6>
                <div className="row">
                    {getDivisions(divisionMappings).map((division, index) => (
                        <div className="col-12 col-sm-12 col-md-6" key={index}>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id={division}
                                    checked={selectedDivisions.includes(division)}
                                    onChange={() => handleDivisionCheckboxChange(division)}
                                />
                                <label className="form-check-label" htmlFor={division}>
                                    {division}
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="mb-3">
                <h6>Rolle</h6>
                <div className="row">
                    {
                        Array.from(roles).map((role, index) => (
                            <div className="col-12 col-sm-12 col-md-6" key={index}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id={role} checked={selectedRoles.includes(role)} onChange={() => handleRoleCheckboxChange(role)} />
                                    <label className="form-check-label" htmlFor={role}>
                                        {role}
                                    </label>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Filters;