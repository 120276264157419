import { FantasyPlayer } from "../../models/FantasyPlayer";
import { FantasyStartTeam } from "../../models/FantasyStartTeam";
import PlayerSlot from "./PlayerSlot";

interface TeamSlotProps {
    team: FantasyStartTeam;
    onPlayerAddClick: (player: FantasyPlayer) => void;
}

const TeamSlot: React.FC<TeamSlotProps> = (props) => {
    return (
        <div className="card mb-3"> {/* Reduced margin-bottom */}
            <div className="card-header">
                <img src={props.team.imageURL} alt="Logo" style={{ width: "5%" }} loading="lazy" />
                {props.team.name}
            </div>
            <div className="card-body">
                <div className="row">
                    {
                        props.team.players.map((player, index) => {
                            if (!player.account || !player.account.steamID) {
                                console.error(`Error: Missing account or steamID for player at index ${index}: with more data ${player.teamId}`, player);
                                return null; // Skip rendering this player if the steamID is missing
                            }

                            return (
                                <div className="col-12 col-md-2 mb-2" key={index}> {/* Reduced margin-bottom */}
                                    <PlayerSlot player={player} onPlayerAddClick={props.onPlayerAddClick} />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default TeamSlot;
