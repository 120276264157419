import { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getFantasyTeams, getFantasyPlayerMatchesByTeamId, getFantasyTeamRanking } from "../../services/FantasyService"; // Added getFantasyLeaderboard
import Loading from "../../components/Loading";
import PlayerSlot from "./PlayerSlot";
import { FantasyTeam } from "../../models/FantasyTeam";
import { FantasyPlayer } from "../../models/FantasyPlayer";
import { FantasyPlayerMatch } from "../../models/FantasyPlayerMatch";
import { divisionMappingsSimple } from "../../Constants";

function formatDateToDanish(dateInput: string | Date): string {
    let date: Date;

    if (dateInput instanceof Date) {
        date = dateInput;
    } else {
        date = new Date(dateInput);
    }

    const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
    };

    return date.toLocaleDateString('da-DK', options).replace("kl.", "").trim();
}

const ViewFantasyTeamPage = () => {
    const { steamID, season, division } = useParams<{ steamID: string; season: string; division?: string }>();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');
    const [team, setTeam] = useState<FantasyTeam | null>(null);
    const [matches, setMatches] = useState<FantasyPlayerMatch[]>([]);
    const [showMatches, setShowMatches] = useState(false);
    const [ranking, setRanking] = useState<number | null>(null); // Added ranking state
    const navigate = useNavigate();

    // Fetch team and leaderboard ranking
    const fetchTeamData = useCallback(async () => {
        if (!steamID || !division) {
            setError("Invalid parameters.");
            setLoading(false);
            return;
        }

        try {
            // Fetch teams
            const teams = await getFantasyTeams(steamID);
            const foundTeam = teams.find(
                (t: FantasyTeam) => t.season === Number(season) && t.division === division
            );
            if (!foundTeam) {
                setError("Team not found");
                return;
            }

            setTeam(foundTeam);

            // Fetch the ranking for this specific team
            const teamRanking = await getFantasyTeamRanking(Number(season), division, steamID);
            setRanking(teamRanking);

        } catch (err) {
            setError(err instanceof Error ? err.message : "An unexpected error occurred");
        } finally {
            setLoading(false);
        }
    }, [steamID, division, season]);

    useEffect(() => {
        fetchTeamData();
    }, [fetchTeamData]);

    const handleViewMatches = async () => {
        if (team) {
            try {
                let fetchedMatches = await getFantasyPlayerMatchesByTeamId(team.id);
                fetchedMatches = fetchedMatches.sort((a, b) => new Date(b.matchTime).getTime() - new Date(a.matchTime).getTime());
                setMatches(fetchedMatches);
                setShowMatches(!showMatches);
            } catch (err) {
                setError("Failed to fetch matches.");
            }
        }
    };

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <div className="text-center"><p>{error}</p></div>;
    }

    if (!team) {
        return <div className="text-center"><p>Holdet blev ikke fundet</p></div>;
    }

    let friendlyDivisionName = "Unknown Division";
    if (division) {
        friendlyDivisionName = divisionMappingsSimple.get(division) || division;
    }

    return (
        <div className="container text-center">
            <h1>{team.account.playerName}'s Fantasy Team</h1>
            <p>Sæson {season}, {friendlyDivisionName}</p>
            <hr />
            <div className="row justify-content-center">
                {ranking !== null && (
                    <p><strong>Nuværende Ranking:</strong> {ranking}</p>
                )}
                <p>Fuld Team Points: {team.points}</p>
            </div>
            <hr />
            <div className="row justify-content-center">
                {team.players.map((player: FantasyPlayer) => (
                    <div className="col-12 col-md-2 mb-4" key={player.account.steamID}>
                        <PlayerSlot player={player} isSelected={true} locked={true} />
                        <div className="text-center p-2 mt-2" style={{ backgroundColor: '#333', color: '#fff', borderRadius: '5px', border: '1px solid #ccc' }}>
                            <p style={{ margin: 0 }}>Player Points: {player.playerPoints}</p>
                            <p style={{ margin: 0 }}>Role Points: {player.rolePoints}</p>
                            <p style={{ margin: 0 }}>Team Points: {player.teamPoints}</p>
                            <p style={{ margin: 0, fontWeight: 'bold' }}><strong>Total Points:</strong> {player.playerPoints + player.rolePoints + player.teamPoints}</p>
                        </div>
                    </div>
                ))}
            </div>

            {team.players.map((player: FantasyPlayer) => (
                <div className="col-12 col-md-2 mb-4" key={player.account.steamID}>

                </div>
            ))}
            <hr />
            <div className="row justify-content-center mt-4">
                <div className="col-12">
                    <button className="btn btn-primary" onClick={handleViewMatches}>
                        {showMatches ? "Gem detaljerede kampe" : "Vis detaljerede kampe"}
                    </button>
                </div>
            </div>
            {showMatches && matches.length > 0 && (
                <div className="row justify-content-center mt-4">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Tid</th>
                                <th>Spillernavn</th>
                                <th>Player Points</th>
                                <th>Role Points</th>
                                <th>Team Points</th>
                                <th>Total Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {matches.map((match: FantasyPlayerMatch) => (
                                <tr key={match.matchId} onClick={() => navigate(`/match/${match.matchId}`)} style={{ cursor: 'pointer' }}>
                                    <td>{formatDateToDanish(match.matchTime)}</td>
                                    <td>{team.players.find(p => p.account.steamID === match.steamId)?.account.playerName}</td>
                                    <td>{match.playerPoints}</td>
                                    <td>{match.rolePoints}</td>
                                    <td>{match.teamPoints}</td>
                                    <td>{match.playerPoints + match.rolePoints + match.teamPoints}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default ViewFantasyTeamPage;
