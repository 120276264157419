import React, { useState } from "react";
import PlayerImage from "../../components/PlayerImage";
import { FantasyPlayer } from "../../models/FantasyPlayer";
import { FantasyRarity } from "../../models/FantasyRarity";
import styles from './PlayerSlot.module.css'; // Import the CSS module

interface PlayerSlotProps {
    player: FantasyPlayer;
    isSelected?: boolean;
    onPlayerAddClick?: (player: FantasyPlayer) => void;
    onPlayerRemoveClick?: (player: FantasyPlayer) => void;
    locked?: boolean; // New prop to indicate if the team is locked
}

const PlayerSlot: React.FC<PlayerSlotProps> = (props) => {
    const [flipped, setFlipped] = useState(false);

    const handleFlip = () => {
        setFlipped(!flipped);
    };

    // Function to format currency
    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('da-DK', {
            style: 'currency',
            currency: 'DKK',
            minimumFractionDigits: 0,
        }).format(amount);
    };

    // Determine the rarity class based on the player's rarity
    const rarityClass = () => {
        switch (props.player.rarity) {
            case FantasyRarity.ShinyGold:
                return styles.ShinyGold;
            case FantasyRarity.Gold:
                return styles.Gold;
            case FantasyRarity.ShinySilver:
                return styles.ShinySilver;
            case FantasyRarity.Silver:
                return styles.Silver;
            case FantasyRarity.Bronze:
                return styles.Bronze;
            default:
                return '';
        }
    };

    // Determine if shiny effect should be applied
    const shinyClass = () => {
        return props.player.rarity === FantasyRarity.ShinyGold || props.player.rarity === FantasyRarity.ShinySilver
            ? styles.shinyEffect
            : '';
    };

    return (
        <div
            className={`card ${styles.flipCard} ${rarityClass()} ${flipped ? styles.flipped : ''}`}
            style={{ minHeight: '290px', maxWidth: '220px', maxHeight: '290px' }} // Slightly larger card size
            onClick={handleFlip}  // Flip when the card is clicked
        >
            <div className={styles.flipCardInner}>
                {/* Front Side of the Card */}
                <div className={`${styles.flipCardFront}`}>
                    <div className="card-body">
                        <div className={shinyClass()}>
                            <PlayerImage id={props.player.account.steamID} width={150} />
                        </div>
                        <div className="text-center">
                            <h5 className="mb-1">{props.player.account.playerName}</h5>
                            <h6 className="mb-1">{formatCurrency(props.player.price)}</h6>
                        </div>
                        <div className="card-footer">
                            {props.isSelected ? (
                                props.locked ? (
                                    <div className="text-center">
                                        <span className="badge bg-secondary">{props.player.role}</span>
                                    </div>
                                ) : (
                                    <button
                                        className="btn btn-danger w-100"
                                        style={{ whiteSpace: 'nowrap', padding: '5px 10px' }}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent flip when button is clicked
                                            props.onPlayerRemoveClick?.(props.player);
                                        }}
                                    >
                                        Remove Player
                                    </button>
                                )
                            ) : (
                                <button
                                    className="btn btn-primary w-100"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent flip when button is clicked
                                        props.onPlayerAddClick?.(props.player);
                                    }}
                                >
                                    Vælg spiller
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                {/* Back Side of the Card */}
                <div className={`${styles.flipCardBack} d-flex flex-column justify-content-between align-items-center p-3`}>
                    <h5 className="mb-2">{props.player.account.playerName}</h5>
                    <ul className="list-unstyled text-center mb-1">
                        <li><strong>Rating:</strong> {props.player.stats?.hltv2Rating ? props.player.stats.hltv2Rating.toFixed(2) : 'N/A'}</li>
                        <li><strong>K/D:</strong> {props.player.stats?.kd ? props.player.stats.kd.toFixed(2) : 'N/A'}</li>
                        <li><strong>First Kills:</strong> {props.player.stats?.fk ?? 'N/A'}</li>
                        <li><strong>KAST:</strong> {props.player.stats?.kast ? props.player.stats.kast.toFixed(2) : 'N/A'}%</li>
                        <li><strong>ADR:</strong> {props.player.stats?.adr ? props.player.stats.adr.toFixed(2) : 'N/A'}</li>
                        <li><strong>HS:</strong> {props.player.stats?.hs ? props.player.stats.hs.toFixed(2) : 'N/A'}%</li>
                        <li><strong>Faceit Elo:</strong> {props.player.account.faceitElo ?? 'N/A'}</li>
                    </ul>
                    <a
                        href={`https://powerstats.dk/player/${props.player.account.steamID}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary mt-1"
                    >
                        View Profile
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PlayerSlot;
