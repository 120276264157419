import React, { useCallback, useState } from "react";
import { Team } from "../models/Team";
import { Link } from "react-router-dom";
import PlayerImage from "./PlayerImage";
import { getPlayersAvgRating } from "../services/MatchService";
import { PlayerMatch } from "../models/PlayerMatch";
import useLocalStorage from 'use-local-storage'; // Import useLocalStorage

interface TeamLineupProps {
    matchId: string;
    team: Team;
    players: PlayerMatch[];
}

const TeamLineup: React.FC<TeamLineupProps> = ({ matchId, team, players }) => {
    const [avgPlayers, setAvgPlayers] = useState<{ [steamId: string]: number }>({});
    const [isDark] = useLocalStorage<boolean>("isDark", false); // Retrieve isDark

    const fetchAvgPlayers = useCallback(async () => {
        try {
            if (!matchId) throw new Error('No matchId provided');
            const avgPlayers = await getPlayersAvgRating(matchId);
            setAvgPlayers(avgPlayers);
        } catch (error) {
            console.error(error);
        }
    }, [matchId]);

    useState(() => {
        fetchAvgPlayers();
    });

    return (
        <div className={`text-center ${isDark ? 'bg-dark text-light' : ''}`}>
            <h2>
                <img src={team.teamLogo} alt="Team logo" className="img-fluid" style={{ width: 35 }} /> {team.teamName}
            </h2>
            <div className="row justify-content-center mx-auto">
                {players.map((player, index) => (
                    <div className="col-6 col-md-2" key={player.steamId}>
                        <Link to={`/player/${player.steamId}`} className="text-decoration-none">
                            <div
                                className="card mb-3"
                                style={
                                    isDark
                                        ? {
                                            backgroundColor: index % 2 === 0 ? '#343a40' : '#212529', // Shifting colors
                                            border: '1px solid #111',
                                        }
                                        : undefined
                                }
                            >
                                <div className="card-body text-center">
                                    <PlayerImage id={player.steamId} width={75} />
                                    <p className={`mt-2 ${isDark ? 'text-light' : 'text-dark'}`}>
                                        {player.account ? player.account.playerName : player.steamId}
                                    </p>
                                    <p className={`mb-0 ${isDark ? 'text-light' : 'text-dark'}`}>
                                        AVG Rating: {Math.round(avgPlayers[player.steamId] * 100) / 100}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TeamLineup;
