import axios from "axios";

export const getNextParsing = async (timeZoneId: string): Promise<string> => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/PowerLiga/GetNextParsing`, {
            params: {
                timeZoneId: timeZoneId
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
