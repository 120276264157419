import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { divisionMappings } from "../../Constants";
import { fetchLFTPlayers } from "../../services/AccountService";
import Loading from "../../components/Loading";
import { LFT } from "../../models/LFT";
import Filters from "./Filters";
import { PremiumBadge } from '../../components/Badges/PremiumBadge';
import { DonatorBadge } from '../../components/Badges/DonatorBadge';
import useLocalStorage from 'use-local-storage'; // Import useLocalStorage

const FreeAgentPage = () => {
  const [originalPlayers, setOriginalPlayers] = useState<LFT[]>([]);
  const [players, setPlayers] = useState<LFT[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Retrieve isDark from localStorage
  const [isDark] = useLocalStorage<boolean>("isDark", false);

  // Define cardStyle based on isDark
  const cardStyle = isDark
    ? { backgroundColor: '#2c2f33', color: '#ffffff', borderColor: '#444' }
    : {};

  // Define a constant for orange button styles
  const orangeButtonStyle = {
    backgroundColor: '#FFA500', // Orange color
    borderColor: '#FFA500',
    color: '#ffffff', // White text for contrast
  };

  const fetchAllPlayers = useCallback(async () => {
    setLoading(true);
    try {
      const playersData = await fetchLFTPlayers();
      setOriginalPlayers(playersData); // Store the original data
      setPlayers(playersData); // Ensure players are set
    } catch (error) {
      console.error('Error fetching players:', error);
      setPlayers([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAllPlayers();
  }, [fetchAllPlayers]);

  const renderPlayerCards = () => {
    if (loading) {
      return (
        <div className="text-center">
          <Loading />
        </div>
      );
    }
    if (players.length === 0) {
      return (
        <div className="text-center">
          <p className={isDark ? 'text-light' : 'text-dark'}>
            Der er ingen spillere der leder efter hold.
          </p>
        </div>
      );
    }

    return (
      <div className="row">
        {players.map((player) => (
          <div className="col-12 col-md-6 col-lg-4" key={player.account.steamID}>
            <div
              className={`card mb-3 text-center`}
              style={cardStyle}
            >
              <div
                className="card-header"
                style={{ position: 'relative', backgroundColor: 'inherit', color: 'inherit' }}
              >
                <Link
                  to={`/player/${player.account.steamID}`}
                  className={`text-decoration-none ${isDark ? 'text-light' : 'text-black'}`}
                >
                  <img
                    src={`https://report.dpl.gg/powerfiles/ligaen/getPlayerPicture.php?id=${player.account.steamID}`}
                    alt="Avatar"
                    className="rounded-circle"
                    width="75"
                  />
                  <h5 className="card-title">
                    {player.account.playerName}
                    {player.account.age === 0 ? "" : ` - ${player.account.age} år`}
                  </h5>
                  <h6 className={`card-subtitle mb-2 ${isDark ? 'text-light' : 'text-muted'}`}>
                    {player.account.role}
                  </h6>
                </Link>
                <div
                  style={{
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    display: 'flex',
                    gap: '5px',
                  }}
                >
                  {player.account.premium && <PremiumBadge />}
                  {player.account.donator && <DonatorBadge />}
                </div>
              </div>
              <div className="card-body" style={{ backgroundColor: 'inherit', color: 'inherit' }}>
                {player.stats ? (
                  <div className="row">
                    <div className="col-6">
                      <p className="card-text">Rating: {player.stats.hltv2Rating}</p>
                      <p className="card-text">K/D: {player.stats.kd}</p>
                      <p className="card-text">KAST: {player.stats.kast}%</p>
                    </div>
                    <div className="col-6">
                      <p className="card-text">Sæson: {player.stats.season}</p>
                      {player.team && (
                        <p className="card-text">
                          Division: {divisionMappings.get(player.team.division)}
                        </p>
                      )}
                      <p className="card-text">ELO: {player.account.faceitElo}</p>
                    </div>
                  </div>
                ) : (
                  player.account.faceitElo && <p className="card-text">ELO: {player.account.faceitElo}</p>
                )}
              </div>
              <div className="card-footer" style={{ backgroundColor: 'inherit', color: 'inherit' }}>
                {/* Use <a> tags for external links with btn-primary and inline styles to keep orange buttons */}
                <a
                  href={`https://steamcommunity.com/profiles/${player.account.steamID}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary m-1"
                  style={orangeButtonStyle}
                >
                  Steam
                </a>
                {player.account.twitter !== "" && (
                  <a
                    href={`https://twitter.com/${player.account.twitter}`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary m-1"
                    style={orangeButtonStyle}
                  >
                    Twitter
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={`container ${isDark ? 'bg-dark text-light' : ''}`}>
      <div className="text-center">
        <h1>Free Agents</h1>
        <p>Her kan du finde spillere der leder efter hold.</p>
      </div>
      <hr />
      <div className="row">
        <div className="col-3">
          <Filters players={originalPlayers} setPlayers={setPlayers} />
        </div>
        <div className="col-9">
          {renderPlayerCards()}
        </div>
      </div>
    </div>
  );
};

export default FreeAgentPage;
