import { Account } from "./Account";
import { FantasyRole } from "./FantasyRole";
import { FantasyRarity } from "./FantasyRarity";
import { PlayerStats } from "./PlayerStats";

export class FantasyPlayer {
    constructor(
        public stats: PlayerStats,
        public account: Account,
        public price: number,
        public role: FantasyRole | null,
        public playerPoints: number,
        public rolePoints: number,
        public teamPoints: number,
        public rarity: FantasyRarity,
        public teamId: number,
        public incomingTeamId?: string // Optional field for internal use
    ) { }

    get totalPoints(): number {
        return this.playerPoints + this.rolePoints + this.teamPoints;
    }
}
