import React from 'react';

interface Requirement {
    title: string;
    content: string;
}

interface PostCardRequirementProps {
    requirements: Requirement[];
    isDark: boolean; // Add isDark prop
}

const PostCardRequirement: React.FC<PostCardRequirementProps> = ({ requirements, isDark }) => {
    const cardStyle = isDark
        ? { backgroundColor: '#2c2f33', color: '#ffffff' } // Replace with your desired color
        : {};

    return (
        <div 
            className="card"
            style={{ 
                ...cardStyle,
                width: "320px", // Fixed width
                height: "100%", // Full height of its container
                marginBottom: "10px", 
                marginRight: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between" 
            }}
        >
            <div className="card-body" style={{ overflow: "hidden" }}>
                {requirements.map((req, index) => (
                    <div key={index} style={{ marginBottom: index !== requirements.length - 1 ? '10px' : '0' }}>
                        <h6 className="card-subtitle mb-2" style={{ color: isDark ? '#ffffff' : '' }}>
                            {req.title}
                        </h6>
                        <p className="card-text">{req.content}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PostCardRequirement;
