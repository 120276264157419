import { useCallback, useEffect, useState } from "react";
import { getUpcomingMatches } from "../services/MatchService";
import { UpcomingMatch } from "../models/UpcomingMatch";
import UpcomingMatchTable from "./UpcomingMatchTable";

// Create a new mapping for simplified division selection
const simpleDivisionMappings = new Map([
    ["ligaen", "Ligaen"],
    ["1div", "1 Division"],
    ["2div", "2 Division"],
    ["3div", "3 Division"],
    ["4div", "4 Division"],
    ["5div", "5 Division"]
]);

const UpcomingMatches = () => {
    const [matchesByDate, setMatchesByDate] = useState<{ [date: string]: UpcomingMatch[] }>({});
    const [matchesByDivision, setMatchesByDivision] = useState<{ [division: string]: UpcomingMatch[] }>({});
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [selectedDivision, setSelectedDivision] = useState<string>("all");

    const fetchData = async () => {
        try {
            const newMatches = await getUpcomingMatches();
            if (!newMatches || newMatches.length === 0) {
                return;
            }
    
            const matchesByDateTemp: { [date: string]: UpcomingMatch[] } = {};
            const matchesByDivisionTemp: { [division: string]: UpcomingMatch[] } = {};
    
            newMatches.forEach(match => {
                const matchDate = match.match_time.split('T')[0]; // Extract date part
                matchesByDateTemp[matchDate] = matchesByDateTemp[matchDate] || [];
                matchesByDateTemp[matchDate].push(match);
    
                const divisions = new Set<string>();
                divisions.add(match.team1.division);
                divisions.add(match.team2.division);
    
                divisions.forEach(division => {
                    let simplifiedDivision;
    
                    // Special case for Ligaen
                    if (division.toLowerCase() === "ligaen") {
                        simplifiedDivision = "ligaen";
                    } else {
                        // Group numeric divisions without specific groups (like "1divB", "3divD")
                        simplifiedDivision = division.match(/^(\ddiv)/)?.[1] || division.match(/^(\d+)/)?.[1] + "div" || division;
                    }
    
                    matchesByDivisionTemp[simplifiedDivision] = matchesByDivisionTemp[simplifiedDivision] || [];
                    matchesByDivisionTemp[simplifiedDivision].push(match);
                });
            });
    
            setMatchesByDate(matchesByDateTemp);
            setMatchesByDivision(matchesByDivisionTemp);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    

    const getLatestDateFromDict = useCallback((dict: { [date: string]: UpcomingMatch[] }) => {
        const dates = Object.keys(dict);
        if (dates.length === 0) {
            return null;
        }

        const latestDate = dates.reduce((latest, date) => {
            return latest < date ? latest : date;
        });

        setSelectedDate(latestDate);
    }, []);

    useEffect(() => {
        getLatestDateFromDict(matchesByDate);
    }, [matchesByDate, getLatestDateFromDict]);

    useEffect(() => {
        fetchData();
    }, []);

    if (Object.keys(matchesByDate).length === 0) {
        return <p className="text-center">Ingen kommende kampe</p>;
    }

    return (
        <div>
            <div className="text-center mb-1">
                <div className="dropdown d-inline-block me-2">
                    <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedDate ? selectedDate : 'Vælg dato'}
                    </button>
                    <ul className="dropdown-menu">
                        {
                            Object.keys(matchesByDate).map((date, index) => (
                                <li key={index}>
                                    <button className="dropdown-item" onClick={() => setSelectedDate(date)}>{date}</button>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className="dropdown d-inline-block">
                    <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedDivision === "all" ? "Alle divisioner" : simpleDivisionMappings.get(selectedDivision)}
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <button className="dropdown-item" onClick={() => setSelectedDivision("all")}>
                                Alle divisioner
                            </button>
                        </li>
                        {
                            Array.from(simpleDivisionMappings.keys()).map((division, index) => (
                                <li key={index}>
                                    <button className="dropdown-item" onClick={() => setSelectedDivision(division)}>
                                        {simpleDivisionMappings.get(division)}
                                    </button>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            {
                selectedDate && (
                    <UpcomingMatchTable matchesForDate={
                        selectedDivision === "all" ? matchesByDate[selectedDate] :
                            matchesByDivision[selectedDivision]?.filter(match => match.match_time.split('T')[0] === selectedDate) || []
                    } />
                )
            }
        </div>
    );
}

export default UpcomingMatches;
