import React from 'react';
import { Link } from 'react-router-dom';
import useLocalStorage from 'use-local-storage'; // Import useLocalStorage

// Define DonationRowProps for the DonationRow component
type DonationRowProps = {
    steamId: string | null;
    name: string;
    donationAmount: number;
    isDark: boolean; // Add isDark prop
};

// DonationRow Component: Displays individual donation rows
const DonationRow = (props: DonationRowProps) => {
    return (
        <tr className={props.isDark ? 'table-dark' : ''}>
            <td style={{ borderBottom: props.isDark ? '1px solid #000' : '' }}>
                {props.steamId ? (
                    <Link to={`/player/${props.steamId}`}>{props.name}</Link>
                ) : (
                    props.name
                )}
            </td>
            <td style={{ borderBottom: props.isDark ? '1px solid #000' : '' }}>
                {props.donationAmount} kr.
            </td>
        </tr>
    );
};

// DonationTable Component: Displays the list of donations
const DonationTable = () => {
    const [isDark] = useLocalStorage<boolean>('isDark', false); // Retrieve isDark

    const donations = [
        { steamId: "76561198082225391", name: 'Mathias "Blobber" Blaabjerg', donationAmount: 500 },
        { steamId: "76561197982707133", name: 'Malte "maltevm" Mehren', donationAmount: 250 },
        { steamId: "76561197970395883", name: 'Lukas "dox" Dockweiler', donationAmount: 200 },
        { steamId: "76561198201191395", name: 'Martin "Mesterlund" Lund', donationAmount: 150 },
        { steamId: "76561198280530766", name: 'Gerard "M0nkey" Harten', donationAmount: 105 },
        { steamId: "76561199122348312", name: 'Carlo "NarnoPjok" Højmann', donationAmount: 100 },
        { steamId: "76561198277353799", name: 'Rasmus "PihLziNh0" Pihl', donationAmount: 100 },
        { steamId: "76561198344216742", name: 'August-Elias "m0vski-" Møller', donationAmount: 50 },
        { steamId: "76561198128064229", name: 'Johannes "Ember" Hagelsø', donationAmount: 50 },
        { steamId: "76561198201496594", name: 'Casper "Reakz" Bundgaard', donationAmount: 50 },
        { steamId: "76561198305405206", name: 'Christian "Doku" Nielsen', donationAmount: 50 },
        { steamId: "76561198013173057", name: 'Magnus "Erikseeen" Eriksen', donationAmount: 25 },
        { steamId: "76561198848242343", name: 'Nikolai "Zengo" Hansen', donationAmount: 25 },
        { steamId: "76561198149417977", name: 'Kim "kimchi" Andersson', donationAmount: 25 },
        { steamId: null, name: 'Sune Faber', donationAmount: 25 },
    ];

    return (
        <table
            className={`table table-striped table-hover ${isDark ? 'table-dark' : ''}`}
            style={{ border: isDark ? '1px solid #000' : 'none' }} // Set border for the whole table
        >
            <thead>
                <tr>
                    <th scope="col">Navn</th>
                    <th scope="col">Beløb</th>
                </tr>
            </thead>
            <tbody>
                {donations.map(({ steamId, name, donationAmount }, index) => (
                    <DonationRow
                        key={index}
                        steamId={steamId}
                        name={name}
                        donationAmount={donationAmount}
                        isDark={isDark} // Pass isDark to DonationRow
                    />
                ))}
            </tbody>
        </table>
    );
};

// DonationBenefitsTable Component: Displays benefits for donation levels
const DonationBenefitsTable = ({ isDark }: { isDark: boolean }) => (
    <table
        className={`table table-striped table-bordered mt-4 ${isDark ? 'table-dark' : ''}`}
        style={{ border: isDark ? '1px solid #000' : 'none' }} // Set border for the whole table
    >
        <thead className={isDark ? 'thead-dark' : 'thead-light'}>
            <tr>
                <th>Fordele</th>
                <th>25 kr. eller mere</th>
                <th>50 kr. eller mere</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Donator badge på alle steder dit navn er vist</td>
                <td className="text-center">&#10003;</td>
                <td className="text-center">&#10003;</td>
            </tr>
            <tr>
                <td>Topplacering på Looking For Team (LFT)</td>
                <td className="text-center">&#10003;</td>
                <td className="text-center">&#10003;</td>
            </tr>
            <tr>
                <td>Topplacering på Looking for Players (LFP) for hold</td>
                <td></td>
                <td className="text-center">&#10003;</td>
            </tr>
            <tr>
                <td>Adgang til content creators Fantasy Leaderboard (WIP)</td>
                <td></td>
                <td className="text-center">&#10003;</td>
            </tr>
        </tbody>
    </table>
);

// DonationsPage Component: Main page rendering donations and benefits
const DonationsPage = () => {
    const [isDark] = useLocalStorage<boolean>('isDark', false); // Retrieve isDark

    return (
        <div className="container text-center">
            <h1>Donationer Hall of Fame</h1>
            <p>Her kan du se alle donationer til Powerstats.</p>
            <hr />
            <DonationTable />
            <hr />
            <p>
                Powerstats vil altid være tilgængelig for alle uden omkostninger.
                Vi står dog over for visse udgifter i form af servere, domæner og andre nødvendigheder, som vi håber at kunne finansiere gennem donationer.
                Hvis du ønsker at lave en anonym donation eller anvende din organisations navn, kontakt os på
                <a href="https://discord.gg/W54Zhj2PD3" target="_blank" rel="noreferrer"> Discord</a> når du har lavet din donation.
            </p>
            <hr />
            <p>Ønsker du at støtte op om projektet, kan du donere her</p>
            <a className="text-decoration-none" href="https://qr.mobilepay.dk/box/eb8fedbd-3c2c-4db7-b65f-5e5f1be50c23/pay-in" target="_blank" rel="noreferrer">
                <button className="btn btn-primary btn-lg">Støt os på MobilePay</button>
            </a>
            <p className="mt-3">Donation på mindst 25 kr. for at blive fremhævet</p>
            <hr />
            <div className="text-left mt-5">
                <h2>Fordele ved Donationer</h2>
                <DonationBenefitsTable isDark={isDark} /> {/* Pass isDark to DonationBenefitsTable */}
            </div>
        </div>
    );
};

export default DonationsPage;
