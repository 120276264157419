import './SeasonScroll.css';

interface SeasonScrollProps {
    seasons: number[];
    selectedSeason: number;
    onSeasonChange: (season: number) => void;
}

const SeasonScroll: React.FC<SeasonScrollProps> = (props: SeasonScrollProps) => {
    const { seasons, selectedSeason, onSeasonChange } = props;

    return (
        <div className="navlist d-flex align-items-start mb-2">
            <div>
                <h6 className="mt-2">Sæsoner &nbsp;</h6>
            </div>
            <div className="nav nav-pills me-3 seasons-scrollable" id="v-pills-tab" role="tablist" aria-orientation="horizontal">
                {
                    seasons.map((season) => (
                        <button
                            key={season}
                            className={`nav-link ${season === selectedSeason ? 'active' : ''}`}
                            id={`v-pills-${season}-tab`}
                            data-bs-toggle="pill"
                            data-bs-target={`#v-pills-${season}`}
                            type="button"
                            role="tab"
                            aria-controls={`v-pills-${season}`}
                            aria-selected={season === selectedSeason}
                            onClick={() => onSeasonChange(season)}
                        >
                            {season}
                        </button>
                    ))
                }
            </div>
        </div>
    );
}

export default SeasonScroll;