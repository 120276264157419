import { useState } from "react";

interface Tab {
    id: string;
    title: string;
}

const tabs: Tab[] = [
    { id: 'upcoming', title: 'Kommende' },
    { id: 'results', title: 'Resultater' },
];

interface MatchTabsProps {
    onTabChange: (tabId: string) => void;
}

const MatchTabs: React.FC<MatchTabsProps> = ({ onTabChange }) => {
    const [selectedTab, setSelectedTab] = useState("upcoming");

    return (
        <ul className="nav d-flex justify-content-center">
            {tabs.map((tab) => (
                <li className="nav-item" key={tab.id}>
                    <button
                        className={`btn nav-link ${selectedTab === tab.id ? 'active' : ''} m-1`}
                        onClick={() => {
                            setSelectedTab(tab.id);
                            onTabChange(tab.id);
                        }}
                    >
                        {tab.title}
                    </button>
                </li>
            ))}
        </ul>
    );
}

export default MatchTabs;
