import { useState, useEffect } from 'react';
import { fetchAccount } from '../services/AccountService';
import { Account } from '../models/Account';

const usePlayerData = (steamId: string | undefined) => {
    const [playerData, setPlayerData] = useState<Account | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!steamId) {
                setLoading(false);
                return;
            }
            try {
                const data = await fetchAccount(steamId);
                if (!data) {
                    throw new Error('Player not found');
                }
                setPlayerData(data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        if (steamId) {
            fetchData();
        }
    }, [steamId]);

    return { playerData, loading, error };
};

export default usePlayerData;
