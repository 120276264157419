import { useCallback, useContext, useEffect, useState } from "react";
import { FantasyTeam } from "../../models/FantasyTeam";
import { getFantasyLeaderboard, getMostPickedPlayers, getSeasons, isCurrentSeasonLocked } from "../../services/FantasyService";
import Loading from "../../components/Loading";
import { currentSeason, divisionMappings } from "../../Constants";
import PlayerImage from "../../components/PlayerImage";
import { Account } from "../../models/Account";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { PremiumBadge } from "../../components/Badges/PremiumBadge";  // Import PremiumBadge
import { DonatorBadge } from "../../components/Badges/DonatorBadge";  // Import DonatorBadge
import './FantasyPage.css'

interface LeaderboardEntry {
    account: Account;
    points: number;
    placement: number;
    isCurrentUser: boolean;
}

type FantasyLeaderboardResponse = { [division: string]: LeaderboardEntry[] };

const FantasyPage = () => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [seasons, setSeasons] = useState<number[]>([]);
    const [season, setSeason] = useState<number>(0);
    const [divisions, setDivisions] = useState<string[]>([]);
    const [division, setDivision] = useState<string | null>(null);
    const [mostPickedPlayers, setMostPickedPlayers] = useState<{ account: Account, picks: number }[]>([]);
    const [isLocked, setIsLocked] = useState<boolean>(false);
    const { user: visitor } = useContext(AuthContext);
    const [leaderboard, setLeaderboard] = useState<FantasyLeaderboardResponse>({});

    const transformTeamToLeaderboardEntry = (team: FantasyTeam, placement: number, currentUserSteamID: string | null): LeaderboardEntry => ({
        account: team.account,
        points: team.points,
        placement: placement,  // Use the passed placement
        isCurrentUser: team.account.steamID === currentUserSteamID
    });



    const fetchIsLocked = useCallback(async () => {
        try {
            const response = await isCurrentSeasonLocked();
            setIsLocked(response);
        } catch (err) {
            setError(err instanceof Error ? err.message : String(err));
        }
    }, []);

    useEffect(() => {
        fetchIsLocked();
    }, [fetchIsLocked]);

    const fetchTopPicks = useCallback(async () => {
        try {
            const response = await getMostPickedPlayers(season);
            setMostPickedPlayers(response);
        } catch (err) {
            setError(err instanceof Error ? err.message : String(err));
        }
    }, [season]);

    const fetchFantasyTeams = useCallback(async () => {
        if (season === 0) return;
        try {
            const response = await getFantasyLeaderboard(season, visitor?.steamID ?? null);

            // Transform the response
            const transformedLeaderboard: FantasyLeaderboardResponse = Object.entries(response).reduce((acc, [division, teams]) => {
                acc[division] = teams.map((team: any) =>
                    transformTeamToLeaderboardEntry(team, team.placement, visitor?.steamID ?? null)  // Pass placement directly from response
                );
                return acc;
            }, {} as FantasyLeaderboardResponse);

            setLeaderboard(transformedLeaderboard);

            // Sort divisions by place "ligaen" first
            const sortedDivisions = Object.keys(transformedLeaderboard).sort((a, b) => {
                if (a === 'ligaen') return -1;
                if (b === 'ligaen') return 1;
                const numA = parseInt(a);
                const numB = parseInt(b);
                return numA - numB;
            });
            const currentDivision = sortedDivisions[0];
            setDivisions(sortedDivisions);
            setDivision(currentDivision);
        } catch (err) {
            setError(err instanceof Error ? err.message : String(err));
        }
    }, [season, visitor]);


    const fetchSeasons = useCallback(async () => {
        try {
            const response = await getSeasons();
            const realHighest = currentSeason;

            // Add current season if it's not already in the list
            if (!response.includes(realHighest)) {
                response.push(realHighest);
            }

            // Sort the seasons in descending order (highest season first)
            const sortedSeasons = response.sort((a, b) => b - a);

            // Only set the season if no season has been selected yet
            if (season === 0) {
                setSeason(realHighest);
            }

            setSeasons(sortedSeasons);
        } catch (err) {
            setError(err instanceof Error ? err.message : String(err));
        }
    }, [season]);


    useEffect(() => {
        setLoading(true);
        setError('');
        try {
            fetchSeasons();
            fetchFantasyTeams();
        } catch (err) {
            setError(err instanceof Error ? err.message : String(err));
        } finally {
            setLoading(false);
        }
    }, [fetchFantasyTeams, fetchSeasons]);

    useEffect(() => {
        fetchTopPicks();
    }, [fetchTopPicks]);

    const divStringInterpolation = (division: string): string => {
        const div = divisionMappings.get(division) || division;
        if (div !== division) {
            return div;
        }

        const newDiv = div + "A";
        const newDivMapping = divisionMappings.get(newDiv);
        return newDivMapping?.slice(0, -1) || newDiv;
    }

    const renderLeaderboard = () => {
        if (!division) {
            return;
        }
        const entriesFromDivision = leaderboard[division];

        if (!entriesFromDivision) {
            return <p>No data available for this division.</p>;
        }

        return (
            <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className='ranking'>#</th>
                                    <th scope="col">Name</th>
                                    <th className="points">Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    entriesFromDivision.map((entry) => (
                                        <tr key={entry.account.steamID}>
                                            <th>{entry.placement}</th>
                                            <td>
                                                <Link to={`/fantasy/view/${entry.account.steamID}/${season}/${division}`}>
                                                    {entry.account.playerName}
                                                    {entry.account.premium && <PremiumBadge />}
                                                    {entry.account.donator && <DonatorBadge />}
                                                </Link>
                                                {entry.isCurrentUser && <span className="ms-2 badge bg-primary">Dig</span>}
                                            </td>
                                            <td className="text-end">{entry.points}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }


    const renderContent = () => {
        if (isLoading) {
            return (
                <div className="text-center">
                    <Loading />
                </div>
            )
        }

        if (error) {
            return (
                <div className="text-center">
                    <p>Der skete en fejl ved hentning af data</p>
                </div>
            )
        }


        return (
            <div>
                <div className="row justify-content-center text-center">
                    <div className="col-12 col-md-4">
                        Vælg sæson
                        <div className="dropdown mb-2">
                            <button className="btn btn-primary dropdown-toggle w-100" type="button" id="seasonDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                {season ? `Sæson ${season}` : 'Vælg en sæson'}
                            </button>
                            <ul className="dropdown-menu w-100" aria-labelledby="seasonDropdown">
                                {seasons.map((season) => (
                                    <li key={season}>
                                        <button className="dropdown-item" onClick={() => setSeason(season)}>
                                            Sæson {season}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                {
                    season !== 0 && (
                        <div className="row justify-content-center text-center">
                            <div className="col-12 col-md-4">
                                Vælg division
                                <div className="dropdown mb-2">
                                    <button className="btn btn-primary dropdown-toggle w-100" type="button" id="divisionDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        {division ? divStringInterpolation(division) : ''}
                                    </button>
                                    <ul className="dropdown-menu text-center w-100" aria-labelledby="divisionDropdown">
                                        {divisions.map((division) => (
                                            <li key={division}>
                                                <button className="dropdown-item" onClick={() => setDivision(division)}>
                                                    {divStringInterpolation(division)}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                }
                <hr />
                {renderLeaderboard()}
                {
                    (mostPickedPlayers.length === 0) && (
                        <div className="text-center">
                            <p>Der er ingen som har lavet et fantasy hold endnu.</p>
                        </div>
                    )
                }
                {
                    mostPickedPlayers.length > 0 ? (
                        <>
                            <hr />
                            <div className="container text-center">
                                <h1>Mest valgte spillere</h1>
                            </div>
                            <hr />
                            <div className="row d-flex align-items-center">
                                {
                                    mostPickedPlayers.map((player, index) => (
                                        <div className="col" key={player.account.steamID}>
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <h5>{index + 1}. {player.account.playerName}</h5>
                                                    <Link to={`/player/${player.account.steamID}`}>
                                                        <PlayerImage id={player.account.steamID} width={100} />
                                                    </Link>
                                                    <h6>{player.picks} picks</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    ) : null
                }


<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Link className="btn btn-secondary mt-3" to={`/fantasy/detailed-picks/${season}`}>
        View Detailed Picks
    </Link>
</div>


            </div>
        )
    }

    return (
        <div>
            <div className="container text-center">
                <h1>Fantasy Leaderboard</h1>
                <p>Se de bedste fantasy hold i denne sæson. </p>
                {
                    visitor && !isLocked && (
                        <Link className="btn btn-primary" to="/fantasy/create">Opret/Opdater Fantasy hold</Link>
                    )
                }
                {
                    !visitor && !isLocked && (
                        <button className="btn btn-primary disabled">Login for at oprette/opdatere fantasy hold</button>
                    )
                }
                {
                    visitor && isLocked && (
                        <>
                            <Link className="btn btn-primary" to={`/fantasy/view/${visitor.steamID}/${season}/${division}`}>Se fantasy hold</Link>
                            <p>Fantasy hold er låst for denne sæson.</p>
                        </>
                    )
                }

            </div>
            <hr />
            {renderContent()}
        </div>
    )
}

export default FantasyPage;