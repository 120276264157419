Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.default = void 0;

var _RangeSlider = _interopRequireDefault(require("./components/RangeSlider"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var _default = _RangeSlider.default;
exports.default = _default;
