import axios from "axios";
import { baseURL } from "../Constants";
import { PlayerMatch } from "../models/PlayerMatch";

const url = `${baseURL}/PlayerMatch`;

const roundPlayerStats = (player: PlayerMatch): PlayerMatch => {
    const roundedPlayer: PlayerMatch = {
        ...player,
        kills: Math.round(player.kills * 100) / 100,
        deaths: Math.round(player.deaths * 100) / 100,
        assists: Math.round(player.assists * 100) / 100,
        hs: Math.round(player.hs * 100) / 100,
        adr: Math.round(player.adr * 100) / 100,
        fk: Math.round(player.fk * 100) / 100,
        kast: Math.round(player.kast * 100) / 100,
        rating2: Math.round(player.rating2 * 100) / 100,
    };
    return roundedPlayer;
}


export const getPlayerMatchesByGameId = async (gameId: string): Promise<PlayerMatch[]> => {
    try {
        const response = await axios.get(`${url}/${gameId}`);
        const playerMatches = response.data as PlayerMatch[];
        return playerMatches.map(roundPlayerStats);
    }
    catch (error) {
        throw error;
    }
}

export const getPlayerMatchBySteamIdAndGameId = async (steamId: string, gameId: string): Promise<PlayerMatch> => {
    try {
        const response = await axios.get(`${url}/${gameId}/${steamId}`);
        const playerMatch = response.data as PlayerMatch;
        return roundPlayerStats(playerMatch);
    }
    catch (error) {
        throw error;
    }
}

