import axios from "axios";
import { baseURL } from "../Constants";
import { Account } from "../models/Account";
import { PlayerTeamHistory } from "../models/PlayerTeamHistory";
import { LFT } from "../models/LFT";
import { PlayerStats } from "../models/PlayerStats";
import { Team } from "../models/Team";

const url = `${baseURL}/Account`;

const roundPlayerStats = (player: PlayerStats): PlayerStats => {
    const roundedPlayer: PlayerStats = {
        ...player,
        kills: Math.round(player.kills * 100) / 100,
        hsKills: Math.round(player.hsKills * 100) / 100,
        deaths: Math.round(player.deaths * 100) / 100,
        assists: Math.round(player.assists * 100) / 100,
        hs: Math.round(player.hs * 100) / 100,
        dmg: Math.round(player.dmg * 100) / 100,
        adr: Math.round(player.adr * 100) / 100,
        kd: Math.round(player.kd * 100) / 100,
        fk: Math.round(player.fk * 100) / 100,
        kast: Math.round(player.kast * 100) / 100,
        hltv1Rating: Math.round(player.hltv1Rating * 100) / 100,
        hltv2Rating: Math.round(player.hltv2Rating * 100) / 100,
        openingDuelWinPercentage: Math.round(player.openingDuelWinPercentage * 100) / 100,
        openingDuelAttemptPercentage: Math.round(player.openingDuelAttemptPercentage * 100) / 100,
        enemyFlashTime: Math.round(player.enemyFlashTime * 100) / 100,
        timeFlashed: Math.round(player.timeFlashed * 100) / 100,
        utilityDamage: Math.round(player.utilityDamage * 100) / 100,
        gamesPlayed: Math.round(player.gamesPlayed * 100) / 100,
    };
    return roundedPlayer;
}


export const fetchAccount = async (steamId: string): Promise<Account | undefined> => {
    try {
        const response = await axios.get(`${url}/${steamId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const fetchPlayerTeamHistory = async (steamId: string): Promise<PlayerTeamHistory[]> => {
    try {
        const response = await axios.get(`${url}/getPlayerTeamHistory?steamId=${steamId}`);
        const playerTeamHistory = response.data as PlayerTeamHistory[];
        playerTeamHistory.forEach(player => {
            player.rating = player.rating !== -1 ? Math.round(player.rating * 100) / 100 : player.rating;
        });
        return playerTeamHistory;
    }
    catch (error) {
        throw error;
    }
}

export const updateAccount = async (playerData: Account, token: string): Promise<boolean> => {
    try {
        const response = await axios.post(`${url}/update`, playerData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const fetchLFTPlayers = async (): Promise<LFT[]> => {
    try {
        const response = await axios.get(`${url}/lft`);
        let players = response.data as LFT[];
        players.forEach(player => {
            if (player.stats)
                player.stats = roundPlayerStats(player.stats);
        });
        return players;

    } catch (error) {
        throw error;
    }
};

export const fetchPlayersByPlayerName = async (name: string): Promise<Account[]> => {
    try {
        const response = await axios.get(`${url}/playersByPlayerName?username=${name}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}
export const fetchPlayersByPlayerNameAndSeason = async (name: string, season: number): Promise<Account[]> => {
    try {
        const response = await axios.get(`${url}/playersByPlayerNameAndSeason?username=${name}&season=${season}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const postAccount = async (account: Account) => {
    try {
        const response = await axios.post(`${url}`, account);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const fetchCurrentTeam = async (steamId: string): Promise<Team | undefined> => {
    try {
        const response = await axios.get(`${url}/getCurrentTeam?steamid=${steamId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}