import { useEffect, useState } from 'react';
import { checkPlayerHasFantasyTeam } from '../services/FantasyService';
import { currentSeason } from '../Constants';

const useHasFantasyTeam = (steamId: string | undefined) => {
    const [hasFantasyTeam, setHasFantasyTeam] = useState<boolean>(false);

    useEffect(() => {
        const fetchFantasyTeamStatus = async () => {
            if (!steamId) return;
            const result = await checkPlayerHasFantasyTeam(steamId, currentSeason);
            setHasFantasyTeam(result);
        };

        fetchFantasyTeamStatus();
    }, [steamId]);

    return hasFantasyTeam;
};

export default useHasFantasyTeam;
