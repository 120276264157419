import { createContext, useState, useEffect } from 'react';
import { Account } from '../models/Account';
import { jwtDecode } from 'jwt-decode';
import posthog from 'posthog-js';

// Define the shape of AuthContext
interface AuthContextType {
    user: Account | null;
    token: string | null;
    setToken: React.Dispatch<React.SetStateAction<string | null>>;
}

// Create context with default values
export const AuthContext = createContext<AuthContextType>({
    user: null,
    token: null,
    setToken: () => { },
});

interface AuthProviderProps {
    children: React.ReactNode;
}

// Helper function to decode and validate token
const getValidToken = (storedToken: string | null): string | null => {
    if (!storedToken) return null;

    try {
        const decodedToken: any = jwtDecode(storedToken);
        const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
        const currentTime = Date.now();

        if (expirationTime > currentTime) {
            // Token is still valid
            return storedToken;
        } else {
            // Token has expired
            localStorage.removeItem('token');
            return null;
        }
    } catch (error) {
        console.error('Error decoding stored token:', error);
        localStorage.removeItem('token');
        return null;
    }
};

// Helper function to handle post-login setup
const handleUserLogin = (token: string | null, setUser: React.Dispatch<React.SetStateAction<Account | null>>) => {
    if (!token) {
        posthog.reset();
        setUser(null);
        return;
    }

    try {
        const decodedToken: any = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
        const currentTime = Date.now();

        if (expirationTime > currentTime) {
            posthog.identify(decodedToken.steamID, decodedToken);
            setUser(decodedToken);
        } else {
            alert('Login expired, please log in again');
            setUser(null);
        }
    } catch (error) {
        console.error('Error decoding token:', error);
        setUser(null);
    }
};

// AuthProvider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [token, setToken] = useState<string | null>(() => {
        const storedToken = localStorage.getItem('token');
        return getValidToken(storedToken);
    });

    const [user, setUser] = useState<Account | null>(null);

    // Update token in localStorage and handle user login state
    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        } else {
            localStorage.removeItem('token');
        }
        handleUserLogin(token, setUser);
    }, [token]);

    return (
        <AuthContext.Provider value={{ setToken, user, token }}>
            {children}
        </AuthContext.Provider>
    );
};
