import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer text-center py-3 mt-3">
            <div className="row mx-auto">
                <div className="col-4">
                    <div>
                        <a href="https://discord.gg/W54Zhj2PD3" target="_blank" rel="noreferrer" className="text-white ms-3">Join vores Discord</a>
                    </div>
                    <div >
                        <a className="text-white" href="https://products.mobilepay.dk/box/pay-in?id=eb8fedbd-3c2c-4db7-b65f-5e5f1be50c23&phone=4618ZJ" target="_blank" rel="noreferrer">
                            Støt PowerStats.dk via MobilePay
                        </a>
                    </div>
                    <div >
                        <Link to="/privacy" className="text-white">Privacy Policy</Link>
                    </div>
                </div>
                <div className="col-4 text-white">
                    <p>
                        PowerStats er en hjemmeside, der leverer statistik for spillere og hold i PowerLigaen.
                    </p>
                </div>
                <div className="col-4 text-white">
                    <span className="text-white">
                        PowerStats er udviklet af <Link className="text-decoration-none" to="https://twitter.com/RealSoerensen" target="_blank" rel="noreferrer">Soerensen</Link> & <Link className="text-decoration-none" to="https://twitter.com/realxGuys" target="_blank" rel="noreferrer">TigerX</Link>
                    </span>
                </div>
            </div>
        </footer >
    );
}

export default Footer;
