import React, { useRef, useEffect, useState } from 'react';
import { RoundEnd } from '../../models/RoundEnd';
import bombDefused from '../../content/roundEnds/bomb_defused.svg';
import bombExploded from '../../content/roundEnds/bomb_exploded.svg';
import ctWin from '../../content/roundEnds/ct_win.svg';
import tWin from '../../content/roundEnds/t_win.svg';
import stopwatch from '../../content/roundEnds/stopwatch.svg';
import emptyHistory from '../../content/roundEnds/emptyHistory.svg';

interface RoundResultsProps {
    roundEnds: { [round: number]: RoundEnd };
    team1Id: string;
    team2Id: string;
}

const RoundResults: React.FC<RoundResultsProps> = ({ roundEnds, team1Id, team2Id }) => {
    const roundEndIcons: { [key: string]: string } = {
        "BombDefused": bombDefused,
        "TargetBombed": bombExploded,
        "CTsWin": ctWin,
        "TerroristsWin": tWin,
        "TargetSaved": stopwatch,
        "NoResult": emptyHistory
    };

    const containerRef = useRef<HTMLDivElement>(null);
    const [linePositions, setLinePositions] = useState<{ top: number; left: number; height: number }[]>([]);

    const roundCount = Object.keys(roundEnds).length;

    useEffect(() => {
        const updateLinePositions = () => {
            if (!containerRef.current) return;

            const containerRect = containerRef.current.getBoundingClientRect();
            const newLinePositions: { top: number; left: number; height: number }[] = [];

            containerRef.current.querySelectorAll('.line-marker').forEach((marker: Element, index) => {
                const markerRect = marker.getBoundingClientRect();
                const left = ((markerRect.left + markerRect.right) / 2) - containerRect.left;
                const top = markerRect.top - containerRect.top;
                const height = containerRect.height;

                newLinePositions.push({ top, left, height });
            });

            setLinePositions(newLinePositions);
        };

        updateLinePositions();
        window.addEventListener('resize', updateLinePositions);

        return () => {
            window.removeEventListener('resize', updateLinePositions);
        };
    }, [roundEnds, team1Id, team2Id]);

    const renderRoundIcon = (roundEnd: RoundEnd, teamId: string) => {
        if (!roundEnd.teamWonID || roundEnd.teamWonID !== teamId) {
            return <img src={roundEndIcons["NoResult"]} alt="No Result" style={iconStyle} />;
        }

        const reason = roundEnd.reason;
        return <img src={roundEndIcons[reason] || roundEndIcons["NoResult"]} alt={reason} style={iconStyle} />;
    };

    const shouldAddLine = (index: number) => {
        if (index === 12) return true;
        if (index === 24) return true;
        if (index > 24 && (index - 24) % 6 === 0) return true;
        return false;
    };

    const containerStyle: React.CSSProperties = {
        display: roundCount >= 37 ? 'grid' : 'inline-grid',
        gridTemplateColumns: `repeat(${roundCount}, ${roundCount > 24 ? 33 : 31}px)`,
        gap: '2px',
        alignItems: 'center',
        justifyContent: roundCount >= 37 ? 'start' : 'center',
        position: 'relative',
        minWidth: 'max-content',
    };

    const roundResultsContainerStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: roundCount > 37 ? undefined : 'center',
        width: '100%',
        overflowX: 'auto',
        padding: '20px 10px',
    };

    const teamRowStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: roundCount >= 37 ? 'flex-start' : 'center',
        alignItems: 'center',
        gridColumn: '1 / -1',
    };

    const dividerStyle: React.CSSProperties = {
        position: 'absolute',
        top: '50%',
        left: '0',
        right: '0',
        height: '2px',
        backgroundColor: '#000',
    };

    const iconStyle: React.CSSProperties = {
        width: '18px',
        margin: '3px',
    };

    const lineStyle = (linePosition: { top: number; left: number; height: number }): React.CSSProperties => ({
        position: 'absolute',
        width: '2px',
        height: `30px`,
        backgroundColor: '#000',
        left: `${linePosition.left}px`,
        top: `${linePosition.top}px`,
        zIndex: 2,
    });

    return (
        <div style={roundResultsContainerStyle}>
            <div ref={containerRef} style={containerStyle}>
                <div style={teamRowStyle}>
                    {Object.entries(roundEnds).map(([round, roundEnd], index) => (
                        <React.Fragment key={round}>
                            {shouldAddLine(index) && (
                                <div className="line-marker">
                                    <img src={roundEndIcons["NoResult"]} alt="Empty History" style={iconStyle} />
                                </div>
                            )}
                            <div>
                                {renderRoundIcon(roundEnd, team1Id)}
                            </div>
                        </React.Fragment>
                    ))}
                </div>

                <div style={dividerStyle}></div>

                <div style={teamRowStyle}>
                    {Object.entries(roundEnds).map(([round, roundEnd], index) => (
                        <React.Fragment key={round}>
                            {shouldAddLine(index) && (
                                <div className="line-marker">
                                    <img src={roundEndIcons["NoResult"]} alt="Empty History" style={iconStyle} />
                                </div>
                            )}
                            <div>
                                {renderRoundIcon(roundEnd, team2Id)}
                            </div>
                        </React.Fragment>
                    ))}
                </div>

                {linePositions.map((position, index) => (
                    <div key={index} style={lineStyle(position)}></div>
                ))}
            </div>
        </div>
    );
};

export default RoundResults;