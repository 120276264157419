import React from 'react';
import steamLogo from "../../content/SoMe/steam.png";
import faceitLogo from "../../content/SoMe/faceit.png";
import twitterLogo from "../../content/SoMe/twitter.png";
import twitchLogo from "../../content/SoMe/twitch.png";
import youtubeLogo from "../../content/SoMe/youtube.png";
import instagramLogo from "../../content/SoMe/instagram.png";
import { Account } from '../../models/Account';

interface DataProps {
    playerData: Account | null;
    steamId: string | undefined;
}

const SocialMediaButtons: React.FC<DataProps> = React.memo(({ playerData, steamId }) => {
    if (!playerData) {
        return <h1>Kunne ikke hente sociale medier</h1>;
    }

    const generateLink = (key: string, href: string | null, src: string, text: string) => {
        if (!href) return null;
        return (
            <div key={key} className={
                filteredSocialMedia.length === 1 ? 'col-12' : `col-${filteredSocialMedia.length % 2 === 0 ? '6' : '4'}`
            }>
                <a href={href} target="_blank" rel="noreferrer" className="btn btn-primary m-2 d-block">
                    <img src={src} alt={text} style={{ width: 20 }} /> {text}
                </a>
            </div>
        );
    };

    const { twitter, twitch, youtube, instagram } = playerData;
    const socialMedia = [
        { key: 'steam', href: `https://steamcommunity.com/profiles/${steamId}/`, src: steamLogo, text: 'Steam' },
        { key: 'faceit', href: playerData.faceitNickname ? `https://www.faceit.com/en/players/${playerData.faceitNickname}` : null, src: faceitLogo, text: 'Faceit' },
        { key: 'twitter', href: twitter ? `https://twitter.com/${twitter}` : null, src: twitterLogo, text: 'Twitter' },
        { key: 'twitch', href: twitch ? `https://www.twitch.tv/${twitch}` : null, src: twitchLogo, text: 'Twitch' },
        { key: 'youtube', href: youtube ? `https://www.youtube.com/@${youtube}` : null, src: youtubeLogo, text: 'Youtube' },
        { key: 'instagram', href: instagram ? `https://www.instagram.com/${instagram}` : null, src: instagramLogo, text: 'Instagram' },
    ];

    const filteredSocialMedia = socialMedia.filter(link => link.href);

    return (
        <>
            <h1 className="text-center">Sociale medier</h1>
            <div className='row'>
                {filteredSocialMedia.map(link => generateLink(link.key, link.href, link.src, link.text))}
            </div>
        </>
    );
});

export default SocialMediaButtons;
