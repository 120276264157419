import axios from "axios";
import { baseURL } from "../Constants";
import { Match } from "../models/Match";
import { MapStats } from "../models/MapStats";
import { UpcomingMatch } from "../models/UpcomingMatch";

const url = `${baseURL}/Match`;

export const getLatestMatches = async (page: number): Promise<Match[]> => {
    try {
        const response = await axios.get(`${url}/latest?page=${page}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getLatestSeason = async (): Promise<number> => {
    try {
        const response = await axios.get(`${url}/latestSeason`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getUpcomingMatches = async (): Promise<UpcomingMatch[]> => {
    try {
        const response = await axios.get(`${url}/getUpcomingMatches`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getUpcomingMatchesForTeam = async (teamId: string): Promise<UpcomingMatch[]> => {
    try {
        const response = await axios.get(`${url}/getUpcomingMatchesForTeam?teamId=${teamId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getOldestSeason = async (): Promise<number> => {
    try {
        const response = await axios.get(`${url}/oldestSeason`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getMatchesForTeamBySeason = async (teamId: string, season: number): Promise<Match[]> => {
    try {
        const response = await axios.get(`${url}/team`, { params: { teamId, season } });
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getVetoStatsForTeamBySeason = async (teamId: string, season: string): Promise<MapStats[]> => {
    try {
        const response = await axios.get(`${url}/getVetoStats`, { params: { teamid: teamId, season } });
        return response.data.map((item: { mapName: string; amountPicked: number; amountBanned: number; amountPlayed: number; amountWon: number; chosenCTPercentage: number; }) => new MapStats(
            item.mapName,
            item.amountPicked,
            item.amountBanned,
            item.amountPlayed,
            item.amountWon,
            item.chosenCTPercentage,
        ));
    }
    catch (error) {
        throw error;
    }
}

export const getMatchesForPlayerBySeason = async (steamId: string, season: number): Promise<Match[]> => {
    try {
        const response = await axios.get(`${url}/player`, { params: { steamId, season } });
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getMatchById = async (matchId: string): Promise<Match[]> => {
    try {
        const response = await axios.get(`${url}/${matchId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getDivisionStandings = async (season: number, division: string) => {
    try {
        const response = await axios.get(`${url}/getAllDivisionStandings?season=${season}&majorDivision=${division}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getDivisionsBySeason = async (season: number): Promise<string[]> => {
    try {
        const response = await axios.get(`${url}/getDivisionsBySeason?season=${season}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getPlayersAvgRating = async (matchId: string): Promise<{ [steamId: string]: number }> => {
    try {
        const response = await axios.get(`${url}/getPlayersAvgRating?matchId=${matchId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}
