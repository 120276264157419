import { Account } from "../../models/Account";
import { Team } from "../../models/Team";
import './SearchBar.css';

interface SearchResultsProps {
    playerResults: Account[];
    teamResults: Team[];
    onPlayerClick: (steamID: string) => void;
    onTeamClick: (teamID: string) => void;
}

const SearchResults: React.FC<SearchResultsProps> = ({
    playerResults,
    teamResults,
    onPlayerClick,
    onTeamClick
}) => (
    <div className="search-results-card">
        {playerResults.length > 0 && (
            <div className="card-body">
                <label className="col-12 text-center">Spillere</label>
                {playerResults.slice(0, 5).map(player => (
                    <button
                        key={player.steamID}
                        className="btn text-start"
                        onClick={() => onPlayerClick(player.steamID)}
                    >
                        {player.playerName}
                    </button>
                ))}
            </div>
        )}
        {teamResults.length > 0 && (
            <div className="card-body">
                <label className="col-12 text-center">Hold</label>
                {teamResults.slice(0, 5).map(team => (
                    <button
                        key={team.teamId}
                        className="btn text-start"
                        onClick={() => onTeamClick(team.teamId)}
                    >
                        {team.teamName}
                    </button>
                ))}
            </div>
        )}
    </div>
);


export default SearchResults;