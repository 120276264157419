import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useTeamData from '../../hooks/useTeamData';
import PlayerImage from '../../components/PlayerImage';
import { getLatestSeason } from '../../services/MatchService';
import Loading from '../../components/Loading';
import styles from '../../styles/EditTeamPage.module.css';  // Import CSS module
import { updateEditTeamPage } from '../../services/TeamService';

const EditTeamPage = () => {
    const { teamId } = useParams<{ teamId: string }>();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const initialSeason = Number(searchParams.get('season')) || 0;
    const [currentSeason, setCurrentSeason] = useState<number>(initialSeason);
    const { teamData, players, loading, error } = useTeamData(teamId, currentSeason);
    const [ownerId, setOwnerId] = useState('');
    const [twitter, setTwitter] = useState('');
    const [website, setWebsite] = useState('');
    const [coachId, setCoachId] = useState('');

    useEffect(() => {
        if (teamData) {
            setOwnerId(teamData.ownerId);
            setTwitter(teamData.twitter);
            setWebsite(teamData.website);
            setCoachId(teamData.coachID);
        }
    }, [teamData]);

    useEffect(() => {
        const loadSeasons = async () => {
            try {
                const latest = await getLatestSeason();
                setCurrentSeason(latest);
            } catch (err) {
                console.error('Error loading latest season:', err);
            }
        };
        if (currentSeason === 0) {
            loadSeasons();
        }
    }, [currentSeason]);

    const handleSaveChanges = async () => {
        try {
            await updateEditTeamPage(teamId!, ownerId, twitter, website, coachId, currentSeason);
            alert('Changes saved successfully');
        } catch (error) {
            console.error('Error saving changes:', error);
            alert('Error saving changes');
        }
    };

    const handleRemovePlayer = (steamID: string) => {
        alert('Work in progress');
    };

    const validateTwitter = (username: string) => {
        if (username.includes('twitter.com') || username.includes('x.com')) {
            return username.replace(/(https?:\/\/)?(www\.)?(twitter|x)\.com\//, '');
        }
        return username;
    };

    if (loading) {
        return (
            <div className="container text-center">
                <Loading />
            </div>
        );
    }

    if (error) {
        return (
            <div className="container text-center">
                <h3>Error loading team data</h3>
                <p>{error.message}</p>
            </div>
        );
    }

    if (!teamData) {
        return (
            <div className="container text-center">
                <h3>No team data available</h3>
            </div>
        );
    }

    return (
        <React.Fragment>
            <h3>Rediger hold</h3>
            <hr />
            <h2>Holdejer</h2>
            <div className="mb-3">
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon3">https://steamcommunity.com/profiles/</span>
                    <input
                        type="text"
                        className="form-control"
                        id="owner-id"
                        aria-describedby="basic-addon3"
                        value={ownerId}
                        onChange={(e) => setOwnerId(e.target.value)}
                    />
                </div>
            </div>

            <h2>Coach</h2>
            <div className="mb-3">
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon3">https://steamcommunity.com/profiles/</span>
                    <input
                        type="text"
                        className="form-control"
                        id="coach-id"
                        aria-describedby="basic-addon3"
                        value={coachId}
                        onChange={(e) => setCoachId(e.target.value)}
                    />
                </div>
            </div>

            <h2>Twitter</h2>
            <div className="mb-3">
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon3">@</span>
                    <input
                        type="text"
                        className="form-control"
                        id="twitter-id"
                        aria-describedby="basic-addon3"
                        value={twitter}
                        onChange={(e) => setTwitter(validateTwitter(e.target.value))}
                    />
                </div>
            </div>

            <h2>Hjemmeside</h2>
            <div className="mb-3">
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon3">https://</span>
                    <input
                        type="text"
                        className="form-control"
                        id="website-id"
                        aria-describedby="basic-addon3"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                    />
                </div>
            </div>

            <hr />
            <h2>Spillere</h2>
            <div className={`row ${styles['edit-team-container']}`}>
                {
                    players.length > 0 ? (
                        players.map((player) => (
                            <div className={`col-3 ${styles['col-3']}`} key={player.steamID}>
                                <div className={`card ${styles['player-card']}`}>
                                    <button
                                        type="button"
                                        className={`btn btn-danger ${styles['remove-btn']}`}
                                        onClick={() => handleRemovePlayer(player.steamID)}
                                    >X</button>
                                    <div className={`card-body ${styles['card-body']}`}>
                                        <div className={styles['player-image']}>
                                            <PlayerImage id={player.steamID} />
                                        </div>
                                        <h5 className={`card-title ${styles['card-title']}`}>{player.account ? player.account.name : player.steamID}</h5>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="col-12">
                            <h3>Holdet har ingen spillere</h3>
                        </div>
                    )
                }
            </div>
            <hr />

            <div className='mb-3'>
                <button type="button" className="btn btn-primary" onClick={handleSaveChanges}>Gem ændringer</button>
            </div>

        </React.Fragment>
    );
};

export default EditTeamPage;
