import { FC, memo } from 'react';
import PlayerImage from '../../../components/PlayerImage';
import { Account } from '../../../models/Account';
import './PlayerInfo.css';
import { divisionMappings } from '../../../Constants';
import { Link } from 'react-router-dom';
import { PremiumBadge } from '../../../components/Badges/PremiumBadge';
import { DonatorBadge } from '../../../components/Badges/DonatorBadge';
import { Team } from '../../../models/Team';

interface PlayerInfoProps {
    playerData: Account | null;
    teamData: Team | null | undefined;
}

const PlayerInfo: FC<PlayerInfoProps> = memo(({ playerData, teamData }) => {


    if (!playerData) {
        return <h1 className='text-center'>Der skete en fejl</h1>;
    }

    return (
        <div>
            {playerData.lft && (
                <h2 className='text-center'>{playerData.playerName} søger hold!</h2>
            )}
            <div className='player-info-container'>
                <div className="player-card">
                    <div className="player-photo">
                        <PlayerImage id={playerData.steamID} />
                    </div>
                    <div className="player-content">
                        <div className="player-info">
                            <h2>
                                {playerData.playerName}{" "}
                                {playerData.premium && <PremiumBadge />}
                                {playerData.donator && <DonatorBadge />}
                            </h2>
                            <div className="player-details">
                                {playerData.name && <div className="detail"><span>{playerData.name}</span></div>}
                                {playerData.age !== 0 && playerData.age !== null && <div className="detail"><span>{playerData.age} år</span></div>}
                                {playerData.role && <div className="detail"><span>{playerData.role}</span></div>}
                                <div className="detail">
                                    {playerData.faceitElo !== null && <span>{playerData.faceitElo} Elo</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {teamData ? (
                    <div className="player-card">
                        <div className="player-photo">
                            <Link to={`/team/${teamData.teamId}`}>
                                <img src={teamData.teamLogo} alt="TeamLogo" />
                            </Link>
                        </div>
                        <div className="player-content">
                            <div className="player-info">
                                <h2>{teamData.teamName}</h2>
                            </div>
                            <div className="player-details">
                                <div className="detail">
                                    <span className="detail-label">Sæson</span>
                                    <span>{teamData.season}</span>
                                </div>
                                <div className="detail">
                                    <span>{divisionMappings.get(teamData.division)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="player-card">
                        <div className="player-photo">
                            <img src="https://www.hltv.org/img/static/team/placeholder.svg" alt="FA" />
                        </div>
                        <div className="player-content">
                            <div className="player-info">
                                <h2>Intet hold registeret denne sæson</h2>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
});

export default PlayerInfo;
