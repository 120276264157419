import { useCallback, useEffect, useState } from "react";
import { fetchTeamByTeamNameAndSeason } from "../../../services/TeamService";
import inferno from "../../../content/maps/de_inferno.jpg";
import mirage from "../../../content/maps/de_mirage.jpg";
import nuke from "../../../content/maps/de_nuke.jpg";
import overpass from "../../../content/maps/de_overpass.jpg";
import ancient from "../../../content/maps/de_ancient.jpg";
import vertigo from "../../../content/maps/de_vertigo.jpg";
import anubis from "../../../content/maps/de_anubis.jpg";
import dust2 from "../../../content/maps/de_dust2.jpg";
import ctIcon from "../../../content/maps/CT.png";
import tIcon from "../../../content/maps/T.png";
import React from "react";
import { Veto } from "../../../models/Veto";
import "./Veto.css";

interface VetoProps {
    vetoString: string;
    season: number;
}

const mapImageMappings: { [key: string]: string } = {
    "Inferno": inferno,
    "Mirage": mirage,
    "Nuke": nuke,
    "Overpass": overpass,
    "Vertigo": vertigo,
    "Ancient": ancient,
    "Anubis": anubis,
    "Dust2": dust2,
    "CT": ctIcon,
    "T": tIcon
};

const VetoComponent: React.FC<VetoProps> = ({ vetoString, season }) => {
    const [vetoes, setVetoes] = useState<Veto[]>([]);

    const parseVetoString = useCallback(async (vetoString: string): Promise<Veto[]> => {
        const vetoes: Veto[] = [];
        const vetoArray = vetoString.split('\n');

        for (let i = 0; i < vetoArray.length; i++) {
            if (vetoArray[i].includes('removed')) {
                const split = vetoArray[i].split(' removed ');
                const team = await fetchTeamByTeamNameAndSeason(split[0], season);
                const map = split[1].split(' ')[0];
                const mapImage = mapImageMappings[map];
                vetoes.push({ team, action: 'Fjernet', map, mapImage });
            } else if (vetoArray[i].includes('picked')) {
                const split = vetoArray[i].split(' picked ');
                const team = await fetchTeamByTeamNameAndSeason(split[0], season);
                const map = split[1].split(' ')[0];
                const mapImage = mapImageMappings[map];
                vetoes.push({ team, action: 'Valgte', map, mapImage });
            } else if (vetoArray[i].includes('choose to start')) {
                const split = vetoArray[i].split(' choose to start ');
                const team = await fetchTeamByTeamNameAndSeason(split[0], season);
                const map = split[1].split(' ')[0];
                const mapImage = mapImageMappings[map];
                vetoes.push({ team, action: 'Valgt', map, mapImage });
            } else if (vetoArray[i].includes('was left over')) {
                const split = vetoArray[i].split(' was left over ');
                const map = split[0].split(' ')[0];
                const mapImage = mapImageMappings[map];
                vetoes.push({ team: null, action: 'Decider', map, mapImage });
            }
        }

        return vetoes;
    }, [season]);

    useEffect(() => {
        const fetchData = async () => {
            const vetoes = await parseVetoString(vetoString);
            setVetoes(vetoes);
        };

        fetchData();
    }, [season, parseVetoString, vetoString]);

    return (
        <div className="map-selection">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                {
                    vetoes.map((veto, index) => (
                        <li key={index} className="nav-item" role="presentation">
                            <div key={index} className={`map-box ${veto.action.toLowerCase()}`}>
                                <img src={veto.mapImage} alt={veto.map} className="map-image" />
                                <div className="map-details">
                                    {veto.team && <img src={veto.team.teamLogo} alt={`${veto.team.teamName} Logo`} className="team-logo" />}
                                    <p className="map-text">{veto.map + " " + veto.action}</p>
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};

export default VetoComponent;
