import { Link } from "react-router-dom";
import PlayerImage from "../../components/PlayerImage";
import { PlayerStats } from "../../models/PlayerStats";

const TeamPlayers = ({ players, averageRating }: { players: PlayerStats[], averageRating: string }) => {
    players.sort((a, b) => b.gamesPlayed - a.gamesPlayed);

    return (
        <div className="text-center">
            <h5>Average Rating: {averageRating}</h5>
            <div className="row text-center">
                {
                    players && players.length > 0 ? players.map((player, index) => (
                        <div className="col m-1" key={index}>
                            <div className="card">
                                <Link to={`/player/${player.steamID}`} className="text-decoration-none text-black">
                                    <div className="card-body">
                                        <PlayerImage id={player.steamID} width={90} />
                                        <h5 className="card-title text-center">{player.account ? player.account?.playerName : player.steamID}</h5>
                                        <h6 className="card-subtitle mb-2 text-muted text-center">{player.hltv2Rating} - Rating</h6>
                                        <h6 className="card-subtitle mb-2 text-muted text-center">{player.gamesPlayed} - Kampe spillet</h6>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))
                    :
                    <div className="text-center">
                        <p>Der er ingen spillere på holdet.</p>
                    </div>
                }
            </div>
        </div>
    );
}


export default TeamPlayers;